import React, { useRef, useLayoutEffect } from "react";
import * as howWeWorkDescStyles from "./howWeWorkDesc.module.scss";
import { HowWeWorkDescData } from "../../../../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HowWeWorkDesc = () => {
  const titleString =
    "We offer specialist end-to-end and strategic full-stack development services.";
  const subtitleString = "How We Work";

  const howWeWorkDescSectionRef = useRef();

  const howWeWorkDescRefs = useRef([]);
  howWeWorkDescRefs.current = [];

  const addToHowWeWorkDescRefs = (el) => {
    if (el && !howWeWorkDescRefs.current.includes(el)) {
      howWeWorkDescRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let howWeWorkDescAnimation = null;
    let howWeWorkDescGradientAnimation = null;

    howWeWorkDescRefs.current.forEach((el, index) => {
      const q = gsap.utils.selector(el);

      howWeWorkDescAnimation = gsap.fromTo(
        q(".howWeWorkDescFadeInUpTextElem"),
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "expo.out",
          stagger: {
            from: "start",
            amount: 0.7,
          },
          duration: 1.5,
          scrollTrigger: {
            id: `section-${index}`,
            trigger: el,
            start: "top center",
            end: `top center-=${el.offsetHeight}`,
            markers: false,

            onEnter: () => {
              el.classList.add("active-gradient");
            },
            onEnterBack: () => {
              el.classList.add("active-gradient");
            },
            onLeave: () => {
              el.classList.remove("active-gradient");
            },
            onLeaveBack: () => {
              el.classList.remove("active-gradient");
            },
          },
        }
      );

      howWeWorkDescGradientAnimation = gsap.fromTo(
        q(".es-active-gradient"),
        {
          yPercent: "-100",
        },
        {
          duration: 0.5,
          ease: "expo.out",
          yPercent: "0",
          scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "top center-=300",
          },
        }
      );
    });

    const fadeInUpHowWeWorkDescTitleAnimation = gsap.fromTo(
      `.fadeInUpHowWeWorkDescTitle`,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        ease: "expo.out",
        stagger: {
          from: "start",
          amount: 0.7,
        },
        duration: 1.5,
        scrollTrigger: {
          id: "how we work desc title",
          trigger: howWeWorkDescSectionRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    return () => {
      fadeInUpHowWeWorkDescTitleAnimation.kill();
      howWeWorkDescAnimation.kill();
      howWeWorkDescGradientAnimation.kill();
    };
  }, []);

  return (
    <section
      ref={howWeWorkDescSectionRef}
      className="es-section es-section__light es-px-0 es-pb-0"
    >
      <div
        className={`fadeInUpHowWeWorkDescTitle ${howWeWorkDescStyles.howWeWorkDescUpper}`}
      >
        {subtitleString}

        <h3>{titleString}</h3>
      </div>
      <div className={howWeWorkDescStyles.howWeWorkDescPoints}>
        {HowWeWorkDescData.map((item, index) => (
          <div
            key={index}
            ref={addToHowWeWorkDescRefs}
            className={howWeWorkDescStyles.howWeWorkDescPoint}
          >
            <span className="es-active-gradient"></span>
            <div className="howWeWorkDescFadeInUpTextElem">
              <strong>0{index + 1}</strong>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowWeWorkDesc;
