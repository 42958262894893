import React, { useRef, useLayoutEffect } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import * as bestInFieldStyles from "./bestInField.module.scss";
import bestInFieldImg1 from "../../../../assets/images/best-in-field-1.webp";
import bestInFieldImg2 from "../../../../assets/images/best-in-field-2.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PolygonVector from "../../../PolygonVector/PolygonVector";
import { StaticImage } from "gatsby-plugin-image";

gsap.registerPlugin(ScrollTrigger);

const BestInField = () => {
  const descString =
    "We provide expert-guided solutions for messy technology challenges, critical projects and poorly executed cases. Want to play God for incomplete, bug-ridden or dysfunctional software? ";

  const titleString =
    "We are straight-forward. We are brutally honest and the best in the field that money can find.";

  const bestInFieldRef = useRef();

  useLayoutEffect(() => {
    const bestInFieldTextReveal = gsap.fromTo(
      ".BIF-tweenWrapper",
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        stagger: {
          from: "start",
          amount: 0.7,
        },
        duration: 1,
        scrollTrigger: {
          id: "best in field text",
          trigger: bestInFieldRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    return () => {
      bestInFieldTextReveal.kill();
    };
  }, []);

  return (
    <section
      ref={bestInFieldRef}
      className={`es-section es-section__dark ${bestInFieldStyles.bestInFieldSection}`}
    >
      <div
        className={`BIF-tweenWrapper ${bestInFieldStyles.bestInFieldUpperText}`}
      >
        <span className={`${bestInFieldStyles.bestInFieldUpperTextSVG}`}>
          <PolygonVector />
        </span>

        <h3>{titleString}</h3>
      </div>
      <div className={bestInFieldStyles.bestInFieldUpperImgsWrapper}>
        <div className={`BIF-tweenWrapper ${bestInFieldStyles.bestInFieldImg}`}>
          <div className={bestInFieldStyles.bestInFieldImgContent}>
            <StaticImage
              src="../../../../assets/images/best-in-field-1.webp"
              alt="Best in field"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>
        <div className={`BIF-tweenWrapper ${bestInFieldStyles.bestInFieldImg}`}>
          <div className={bestInFieldStyles.bestInFieldImgContent}>
            <StaticImage
              src="../../../../assets/images/best-in-field-2.webp"
              alt="Best in field"
              layout="fullWidth"
              placeholder="none"
            />
          </div>
        </div>
      </div>
      <div
        className={`BIF-tweenWrapper ${bestInFieldStyles.bestInFieldBottomText}`}
      >
        <p className="es-mb-60">{descString}</p>
        <CTAButton
          type="button"
          size="btn-lg"
          text="Join us"
          rounded={true}
          outlined={false}
          btnBG="es-btn-white"
          spacingClasses=""
          hasIcon={false}
          icon=""
          reverse={false}
          isLink={true}
          href="/work-with-us"
          isShadow={false}
        />
      </div>
    </section>
  );
};

export default BestInField;
