import React, { useLayoutEffect, useRef } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import * as whyUsMultiple from "./whyUsMultiple.module.scss";
import StarVector from "../../../StarVector/StarVector";
import { WhyUsData } from "../../../../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WhyUsMultiple = () => {
  const whyUsSectionRefs = useRef([]);
  whyUsSectionRefs.current = [];

  const lineVectorWrapper = useRef();

  const addToWhyUsSectionRefs = (el) => {
    if (el && !whyUsSectionRefs.current.includes(el)) {
      whyUsSectionRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let whyUsSectionAnimation = null;
    let lineVectorDrawAnimation = null;

    whyUsSectionRefs.current.forEach((el, index) => {
      const q = gsap.utils.selector(el);

      whyUsSectionAnimation = gsap.fromTo(
        q(".WU-tweenWrapper"),
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            id: `section-${index}`,
            trigger: el,
            start: "top center",
            end: "top top",
            markers: false,
          },
        }
      );

      if (window.innerWidth >= 1200) {
        ScrollTrigger.create({
          trigger: el,
          start: "top top",
          end: "top top-=2500",
          // pin: true,
          // pinSpacing: false,
        });

        const getPaths = document.querySelectorAll(
          ".es-draw-path-why-us svg path"
        );
        getPaths[index].style.strokeDasharray =
          getPaths[index].getTotalLength();

        lineVectorDrawAnimation = gsap.fromTo(
          q("svg path"),
          {
            strokeDashoffset: getPaths[index].getTotalLength(),
          },
          {
            duration: 2,
            strokeDashoffset: 0,
            scrollTrigger: {
              trigger: el,
              start: "top center",
              end: "top top",
              markers: false,
            },
          }
        );
      }
    });

    return () => {
      whyUsSectionAnimation.kill();
      lineVectorDrawAnimation.kill();
    };
  }, []);

  return WhyUsData.map((item, index) => (
    <section
      ref={addToWhyUsSectionRefs}
      key={index}
      className={`es-section es-centered-full-viewport es-px-0 ${
        item.colorMode === "light" ? "es-section__light" : "es-section__dark"
      } `}
    >
      <div className="es-row align-item-center">
        <div
          className={`es-col ${whyUsMultiple.text_wrapper} ${
            item.colorMode === "light"
              ? whyUsMultiple.light_section
              : whyUsMultiple.dark_section
          }`}
        >
          <div
            className={`WU-tweenWrapper ${whyUsMultiple.text_inner_wrapper}`}
          >
            {item.subtitle}

            <h2 className="es-mb-utility-32-24">{item.title}</h2>
            {item.desc && (
              <div className="es-flex es-mb-utility-84-32">
                <div className={whyUsMultiple.star_wrapper}>
                  <StarVector />
                </div>
                <p className="es-ml-4">{item.desc}</p>
              </div>
            )}
            <CTAButton
              type="button"
              size="btn-lg"
              text={item.CTAText}
              rounded={true}
              outlined={false}
              btnBG={
                item.colorMode === "light" ? "es-btn-blue" : "es-btn-white"
              }
              spacingClasses=""
              hasIcon={false}
              icon=""
              reverse={false}
              isLink={item.link ? true : false}
              href={item.link}
              isShadow={false}
            />
          </div>
        </div>
        <div
          ref={lineVectorWrapper}
          className={`es-col es-draw-path-why-us ${whyUsMultiple.vector_wrapper}`}
        >
          {item.svg}
        </div>
      </div>
    </section>
  ));
};

export default WhyUsMultiple;
