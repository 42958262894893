import React from "react";

const PolygonVector = () => {
  return (
    <>
      <svg
        width="87"
        height="93"
        viewBox="0 0 87 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2341 18.5244C33.9823 17.0304 33.7926 15.5535 33.6701 14.0763C33.4854 11.8606 33.5996 9.62795 33.5341 7.41224C33.5056 6.47224 33.3931 4.02156 33.4384 3.66906C33.8497 0.59727 36.3289 0.127212 37.0541 0.0432838C37.4049 -0.00707332 41.0071 -0.40975 41.7843 3.58525C42.7377 8.46989 43.0432 13.388 42.7058 18.2055C43.4931 22.3013 44.8393 26.3801 46.6169 30.073C53.6904 44.7605 67.1693 46.1873 81.7729 46.053C84.0188 46.0194 85.8838 47.7819 86.0013 50.0144C86.1188 52.2637 84.4469 54.2108 82.2111 54.4122C74.0482 55.2012 59.5654 61.7477 54.5582 68.5627C53.5107 69.9895 52.9015 73.4306 52.0957 76.8045C50.9711 81.4877 49.6333 86.1205 48.1343 88.0677C47.9094 88.3698 45.175 91.4585 44.3592 91.9117C42.3986 92.986 40.8292 92.3648 39.9379 91.7941C39.0466 91.2233 38.0377 90.0987 37.6349 88.2355C37.2135 86.2884 37.5694 82.2933 37.5039 81.4372C37.2656 78.3318 36.6446 72.2387 35.3772 67.0015C34.6941 64.1815 33.9655 61.5797 32.7603 60.2872C28.2987 55.5033 21.1647 56.2084 15.1571 56.9973C14.4672 57.0812 13.7774 57.182 13.0891 57.2659C10.6435 57.9877 8.04665 58.5248 5.30218 58.8437C1.15779 59.3305 0.476333 55.5201 0.439405 55.2683C0.36219 54.7144 0.0751242 51.8609 3.19895 50.7195C3.5397 50.602 6.05422 50.048 7.02276 49.8298C8.37233 49.5276 9.73698 49.2927 11.1067 49.0745C25.2554 44.6766 32.9399 32.3222 34.2341 18.5244ZM39.0902 33.7827C44.4314 44.8277 52.5372 50.1655 62.1454 52.5827H62.1437C56.1445 55.7216 50.6874 59.6495 47.7952 63.5942C46.6974 65.0881 45.6903 68.0927 44.7822 71.4666C44.2904 68.3277 43.6257 64.9872 42.7478 62.1336C41.7491 58.8772 40.4045 56.1749 38.9038 54.5635C36.0066 51.4581 32.3708 49.7291 28.4614 48.8731C33.1413 44.727 36.6898 39.557 39.0902 33.7827Z"
          fill="#0066CC"
        />
      </svg>
    </>
  );
};

export default PolygonVector;
