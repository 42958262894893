import React, { useRef, useLayoutEffect } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import * as whatWeDoStyles from "./whatWeDo.module.scss";
import StarVector from "../../../StarVector/StarVector";
import { WhatWeDoData } from "../../../../../data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WhatWeDo = () => {
  const whatWeDoSectionRefs = useRef([]);
  whatWeDoSectionRefs.current = [];

  const lineVectorWrapper = useRef();

  const addToWhatWeDoSectionRefs = (el) => {
    if (el && !whatWeDoSectionRefs.current.includes(el)) {
      whatWeDoSectionRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let whatWeDoSectionAnimation = null;
    let lineVectorDrawAnimation = null;

    whatWeDoSectionRefs.current.forEach((el, index) => {
      const q = gsap.utils.selector(el);

      whatWeDoSectionAnimation = gsap.fromTo(
        q(".WWD-tweenWrapper"),
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            id: `section-${index}`,
            trigger: el,
            start: "top center",
            end: "top top",
            markers: false,
          },
        }
      );

      if (window.innerWidth >= 1200) {
        ScrollTrigger.create({
          trigger: el,
          start: "top top",
          end: "top top-=2500",
          // pin: true,
          // pinSpacing: false,
        });

        const getPaths = document.querySelectorAll(".es-draw-path svg path");
        getPaths[index].style.strokeDasharray =
          getPaths[index].getTotalLength();

        lineVectorDrawAnimation = gsap.fromTo(
          q("svg path"),
          {
            strokeDashoffset: getPaths[index].getTotalLength(),
          },
          {
            duration: 2,
            strokeDashoffset: 0,
            scrollTrigger: {
              id: `section-${index}-path-drawing`,
              trigger: el,
              start: "top center",
              end: "top top",
              markers: false,
            },
          }
        );
      }
    });

    return () => {
      whatWeDoSectionAnimation.kill();
      lineVectorDrawAnimation.kill();
    };
  }, []);

  return WhatWeDoData.map((item, index) => (
    <section
      ref={addToWhatWeDoSectionRefs}
      key={index}
      className={`es-section es-centered-full-viewport es-px-0 whatWeDoMultiple-${index} ${
        item.colorMode === "light" ? "es-section__light" : "es-section__dark"
      } `}
    >
      <div className="es-row align-item-center">
        <div
          className={`es-col ${whatWeDoStyles.text_wrapper} ${
            item.colorMode === "light"
              ? whatWeDoStyles.light_section
              : whatWeDoStyles.dark_section
          }`}
        >
          <div
            className={`WWD-tweenWrapper ${whatWeDoStyles.text_inner_wrapper}`}
          >
            <span className={whatWeDoStyles.subtitle}>{item.subtitle}</span>
            <h2 className="es-mb-utility-32-24">
              {item.title}
              <strong>{item.titleStrongText}</strong>
            </h2>
            <div className="es-flex es-mb-utility-84-32">
              <div className={whatWeDoStyles.star_wrapper}>
                <StarVector />
              </div>
              <p className="es-ml-4">{item.desc}</p>
            </div>
            <CTAButton
              type="button"
              size="btn-lg"
              text={item.CTAText}
              rounded={true}
              outlined={false}
              btnBG={
                item.colorMode === "light" ? "es-btn-blue" : "es-btn-white"
              }
              spacingClasses=""
              hasIcon={false}
              icon=""
              reverse={false}
              isLink={item.link ? true : false}
              href={item.link}
              isShadow={false}
            />
          </div>
        </div>
        <div
          ref={lineVectorWrapper}
          className={`es-col es-draw-path ${whatWeDoStyles.vector_wrapper}`}
        >
          {item.svg}
        </div>
      </div>
    </section>
  ));
};

export default WhatWeDo;
