import React, { useRef, useLayoutEffect } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import * as industriesStyles from "./industries.module.scss";
import marqueeImgFinance from "../../../../assets/images/industry-finance.webp";
import marqueeImgTransport from "../../../../assets/images/industry-transport.webp";
import marqueeImgTechnology from "../../../../assets/images/industry-technology.webp";
import marqueeImgHealthcare from "../../../../assets/images/industry-healthcare.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Industries = () => {
  const industriesSectionRef = useRef();

  const infiniteTilesRef = useRef();
  let infiniteTilesTimeline = useRef();

  useLayoutEffect(() => {
    if (window.innerWidth >= 1200) {
      infiniteTilesTimeline.current = gsap.timeline().fromTo(
        infiniteTilesRef.current,
        { x: 0 },
        {
          duration: 20,
          xPercent: -100,
          repeat: -1,
          yoyo: true,
          ease: "none",
        }
      );
    }
    const fadeInUpIndustriesTextAnimation = gsap.fromTo(
      `.IS-tweenWrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          id: "industries",
          trigger: industriesSectionRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    return () => {
      infiniteTilesTimeline.current.kill();
      fadeInUpIndustriesTextAnimation.kill();
    };
  }, []);

  const pauseAnimationHandler = () => {
    infiniteTilesTimeline.current.pause();
  };
  const playAnimationHandler = () => {
    infiniteTilesTimeline.current.play();
  };

  const subtitleString = "Industries Served";
  const descString =
    "Geography or industry does not limit us. We help push through with a solution destined for success.";

  return (
    <section
      ref={industriesSectionRef}
      className={`es-section es-section__dark es-full-viewport ${industriesStyles.industriesSection}`}
    >
      <div
        className={`IS-tweenWrapper ${industriesStyles.industriesTextWrapper}`}
      >
        <span className="subtitle">{subtitleString}</span>
        <h3 className="es-mb-64">{descString}</h3>
        <CTAButton
          type="button"
          size="btn-lg"
          text="hall of fame"
          rounded={true}
          outlined={true}
          btnBG="es-btn-white"
          spacingClasses=""
          additionalClass="noHover"
          hasIcon={false}
          icon=""
          reverse={false}
          isLink={true}
          isShadow={false}
          href="/hall-of-fame"
        />
      </div>
      <div
        className={`${industriesStyles.industriesMarqueeWrapper} ${industriesStyles.industriesMarqueeWrapperDesktop}`}
      >
        <div
          ref={infiniteTilesRef}
          onMouseEnter={pauseAnimationHandler}
          onMouseLeave={playAnimationHandler}
          className={industriesStyles.industriesMarqueeBoxes}
        >
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Transport</span>
            <img loading="lazy" src={marqueeImgTransport} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Technology</span>
            <img loading="lazy" src={marqueeImgTechnology} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Finance</span>
            <img loading="lazy" src={marqueeImgFinance} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Healthcare</span>
            <img loading="lazy" src={marqueeImgHealthcare} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Transport</span>
            <img loading="lazy" src={marqueeImgTransport} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Technology</span>
            <img loading="lazy" src={marqueeImgTechnology} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Finance</span>
            <img loading="lazy" src={marqueeImgFinance} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Healthcare</span>
            <img loading="lazy" src={marqueeImgHealthcare} alt="marqueeImg" />
          </div>
          <div className={industriesStyles.industriesMarqueeBox}>
            <span>Transport</span>
            <img loading="lazy" src={marqueeImgTransport} alt="marqueeImg" />
          </div>
        </div>
      </div>
      <div
        className={`${industriesStyles.industriesMarqueeWrapperMobile} ${industriesStyles.industriesMarqueeWrapper}`}
      >
        <div className={industriesStyles.industriesMarqueeBoxes}>
          <div
            className={`fadeInUpIndustriesText ${industriesStyles.industriesMarqueeBox}`}
          >
            <span>Technology</span>
          </div>
          <div
            className={`fadeInUpIndustriesText ${industriesStyles.industriesMarqueeBox}`}
          >
            <span>Finance</span>
          </div>
          <div
            className={`fadeInUpIndustriesText ${industriesStyles.industriesMarqueeBox}`}
          >
            <span>Healthcare</span>
          </div>
          <div
            className={`fadeInUpIndustriesText ${industriesStyles.industriesMarqueeBox}`}
          >
            <span>Transport</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
