import React, { useRef, useLayoutEffect } from "react";
import * as TechRehabStyles from "./techRehab.module.scss";
import CTAButton from "../../../CTAButton/CTAButton";
import coverImg from "../../../../assets/images/tech-rehab.jpg";
import StarVector from "../../../StarVector/StarVector";

import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

const TechRehab = () => {
  const techRehabSectionRef = useRef();
  const imgRevealWrapperRef = useRef();

  const titleString = "Tech rehab for struggling softwares.";
  const descString =
    "A new life for broken tech with the only shift you need. There is always a way forward. Let’s find the one for your software.";

  useLayoutEffect(() => {
    const imgRevealWrapperTween = gsap.fromTo(
      imgRevealWrapperRef.current,
      {
        opacity: 0,
        y: "10%",
      },
      {
        duration: 0.5,
        delay: 0.3,
        opacity: 1,
        y: "0",
        scrollTrigger: {
          id: "tech rehab img",
          trigger: techRehabSectionRef.current,
          start: "top center-=200",
          end: "top top",
          markers: false,
        },
      }
    );

    const fadeInUpTechRehabTextAnimation = gsap.fromTo(
      `.TR-tweenWrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          id: "tech rehab text",
          trigger: techRehabSectionRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    return () => {
      fadeInUpTechRehabTextAnimation.kill();
      imgRevealWrapperTween.kill();
    };
  }, []);

  return (
    <section
      ref={techRehabSectionRef}
      className={`es-section es-section__dark ${TechRehabStyles.techRehabSection}`}
    >
      <div
        className={`es-mx-auto TR-tweenWrapper ${TechRehabStyles.text_wrapper}`}
      >
        <h2 className="es-mb-utility-48-24">{titleString}</h2>
        <p className={`es-mb-utility-64-32`}>{descString}</p>

        <CTAButton
          type="button"
          size="btn-lg"
          text="Let's talk"
          rounded={true}
          outlined={false}
          btnBG="es-btn-white"
          spacingClasses=""
          hasIcon={false}
          icon=""
          href="/contact-us"
          reverse={false}
          isLink={true}
          isShadow={false}
        />
        <StarVector />
      </div>
      <div
        ref={imgRevealWrapperRef}
        className={`es-mt-utility-120-60 es-mx-auto ${TechRehabStyles.img_wrapper}`}
      >
        <StaticImage
          src="../../../../assets/images/tech-rehab.jpg"
          alt="Tech rehab"
          layout="fullWidth"
          placeholder="none"
        />
      </div>
    </section>
  );
};

export default TechRehab;
