import React, { useRef, useLayoutEffect } from "react";
import * as howWeWorkStyles from "./howWeWorkCover.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HowWeWorkCover = () => {
  const howWeWorkCoverSectionRef = useRef();
  const titleString = "How We Work";

  useLayoutEffect(() => {
    const fadeInUpHowWeWorkTextAnimation = gsap.fromTo(
      `.HWW-tweenWrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          id: "how we work cover",
          trigger: howWeWorkCoverSectionRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    let proxy = { skew: 0 },
      skewSetter = gsap.quickSetter(".howWeWrokVerticalLines", "skewX", "deg"),
      clamp = gsap.utils.clamp(-20, 20);

    ScrollTrigger.create({
      onUpdate: (self) => {
        let skew = clamp(self.getVelocity() / -300);

        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 5,
            ease: "power3",
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
    });

    gsap.set(".howWeWrokVerticalLines", {
      transformOrigin: "right center",
      force3D: true,
    });

    return () => {
      fadeInUpHowWeWorkTextAnimation.kill();
    };
  }, []);

  return (
    <section
      ref={howWeWorkCoverSectionRef}
      className={`es-section es-section__light es-px-0 ${howWeWorkStyles.howWeWorkCoverSection}`}
    >
      <div className={howWeWorkStyles.howWeWorkContent}>
        <h2 className="HWW-tweenWrapper">{titleString}</h2>
        <div className={howWeWorkStyles.svgWrapper}>
          <svg
            width="1440"
            height="421"
            viewBox="0 0 1440 421"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="howWeWrokVerticalLines"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-46 420.512L-32.7535 210.343L-19.5069 5.98771e-05L-18.0968 5.98155e-05L-4.85029 210.343L8.39625 420.512L-46 420.512ZM11.7734 420.512L24.0371 210.343L36.2581 5.74395e-05L39.762 5.72864e-05L51.9403 210.343L64.204 420.512L11.7734 420.512ZM80.826 210.343L69.5879 420.512L120.01 420.512L108.729 210.343L97.6192 5.47574e-05L91.9788 5.50039e-05L80.826 210.343ZM127.359 420.512L137.615 210.343L147.699 5.25683e-05L155.433 5.22302e-05L165.518 210.343L175.816 420.512L127.359 420.512ZM194.405 210.343L185.133 420.512L231.624 420.512L222.308 210.343L213.292 4.97011e-05L203.464 5.01307e-05L194.405 210.343ZM242.902 420.512L251.192 210.343L259.226 4.76933e-05L271.105 4.71741e-05L279.095 210.343L287.428 420.512L242.902 420.512ZM307.979 210.343L300.715 420.512L343.189 420.512L335.882 210.343L328.96 4.46451e-05L314.944 4.52578e-05L307.979 210.343ZM358.488 420.512L364.77 210.343L370.709 4.28202e-05L386.776 4.21179e-05L392.715 210.343L398.997 420.512L358.488 420.512ZM421.603 210.343L416.305 420.512L454.805 420.512L449.506 210.343L444.592 3.95907e-05L426.475 4.03826e-05L421.603 210.343ZM474.076 420.512L478.349 210.343L482.238 3.79451e-05L502.407 3.70635e-05L506.295 210.343L510.568 420.512L474.076 420.512ZM535.179 210.343L531.889 420.512L566.33 420.512L563.082 210.343L560.219 3.45365e-05L537.999 3.55077e-05L535.179 210.343ZM589.705 420.512L591.97 210.343L593.765 6.35877e-05L618.036 6.25268e-05L619.873 210.343L622.138 420.512L589.705 420.512Z"
              fill="url(#paint0_linear_515_3333)"
            />
            <path
              className="howWeWrokVerticalLines"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M647.477 420.512L648.758 210.343L649.528 6.09455e-05L675.85 5.97949e-05L676.661 210.343L677.901 420.512L647.477 420.512ZM705.291 420.512L705.547 210.343L705.291 5.8508e-05L733.664 5.72678e-05L733.451 210.343L733.664 420.512L705.291 420.512ZM762.339 210.343L763.108 420.512L789.473 420.512L790.242 210.343L791.481 8.52581e-05L761.057 8.6588e-05L762.339 210.343ZM820.921 420.512L819.126 210.343L816.861 8.41487e-05L849.294 8.2731e-05L847.029 210.343L845.234 420.512L820.921 420.512Z"
              fill="url(#paint1_linear_515_3333)"
            />
            <path
              className="howWeWrokVerticalLines"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M875.913 210.343L878.734 420.512L900.996 420.512L903.817 210.343L907.107 5.95278e-05L872.623 6.10352e-05L875.913 210.343ZM932.704 210.343L936.55 420.512L956.762 420.512L960.608 210.343L964.881 5.70024e-05L928.389 5.85976e-05L932.704 210.343ZM994.365 420.512L989.494 210.343L984.195 5.61582e-05L1022.7 5.44753e-05L1017.4 210.343L1012.53 420.512L994.365 420.512ZM1046.28 210.343L1052.18 420.512L1068.25 420.512L1074.19 210.343L1080.51 5.19481e-05L1040 5.37188e-05L1046.28 210.343ZM1110.04 420.512L1103.07 210.343L1095.77 5.12812e-05L1138.28 4.94227e-05L1130.98 210.343L1124.01 420.512L1110.04 420.512ZM1159.86 210.343L1167.85 420.512L1179.77 420.512L1187.77 210.343L1196.05 4.68975e-05L1151.57 4.88419e-05L1159.86 210.343ZM1225.71 420.512L1216.65 210.343L1207.33 4.64045e-05L1253.87 4.43704e-05L1244.55 210.343L1235.54 420.512L1225.71 420.512ZM1273.44 210.343L1283.52 420.512L1291.26 420.512L1301.34 210.343L1311.64 4.18451e-05L1263.14 4.39651e-05L1273.44 210.343ZM1341.38 420.512L1330.23 210.343L1318.95 7.20434e-05L1369.41 6.98375e-05L1358.13 210.343L1347.02 420.512L1341.38 420.512ZM1387.02 210.343L1399.2 420.512L1402.74 420.512L1414.92 210.343L1427.19 6.7312e-05L1374.76 6.96038e-05L1387.02 210.343ZM1457.06 420.512L1443.81 210.343L1430.56 6.71644e-05L1485 6.47848e-05L1471.71 210.343L1458.51 420.512L1457.06 420.512Z"
              fill="url(#paint2_linear_515_3333)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_515_3333"
                x1="-159.5"
                y1="452.5"
                x2="622"
                y2="11"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#EB3300" />
                <stop offset="1" stopColor="#0066CC" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_515_3333"
                x1="653.698"
                y1="18.5001"
                x2="1755.03"
                y2="-321.553"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.10111" stopColor="#0066CC" />
                <stop offset="1" stopColor="#EB3300" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_515_3333"
                x1="891.5"
                y1="18.5"
                x2="1589.5"
                y2="398"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0066CC" />
                <stop offset="1" stopColor="#EB3300" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
};

export default HowWeWorkCover;
