import React from "react";
import "../assets/styles/index.scss";
import TechRehab from "../components/sections/IndexPageSections/TechRehab/TechRehab";
import WhatWeDo from "../components/sections/IndexPageSections/WhatWeDoCopy/WhatWeDo";
import WhyUsMultiple from "../components/sections/IndexPageSections/WhyUsMultipleCopy/WhyUsMultiple";
import Solution from "../components/sections/IndexPageSections/Solution/Solution";
import WhatWeDoCover from "../components/sections/IndexPageSections/WhatWeDoCover/WhatWeDoCover";
import WhyUsCover from "../components/sections/IndexPageSections/WhyUsCover/WhyUsCover";
import HowWeWorkCover from "../components/sections/IndexPageSections/HowWeWorkCover/HowWeWorkCover";
import HowWeWorkDesc from "../components/sections/IndexPageSections/HowWeWorkDesc/HowWeWorkDesc";
import FutureProof from "../components/sections/IndexPageSections/FutureProof/FutureProof";
import Industries from "../components/sections/IndexPageSections/Industries/Industries";
import BestInField from "../components/sections/IndexPageSections/BestInField/BestInField";
import Footer from "../components/Footer/Footer";
import Cover from "../components/sections/IndexPageSections/Cover/Cover";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Endshift | The shift you need</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-light" />
      <main>
        <Cover />
        <TechRehab />
        <WhatWeDoCover />
        <FutureProof />
        <WhatWeDo />

        <Industries />
        <HowWeWorkCover />
        <HowWeWorkDesc />
        <WhyUsCover />
        <WhyUsMultiple />

        <BestInField />
        <Solution />
      </main>
      <Footer />
    </>
  );
};

export default Index;
