import React, { useRef, useLayoutEffect } from "react";
import * as whyUsCoverStyles from "./whyUsCover.module.scss";
import whyUsCoverImg from "../../../../assets/images/why-us-cover-img.webp";

import StarVector from "../../../StarVector/StarVector";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WhyUsMobileVector from "../../../WhyUsMobileVector/WhyUsMobileVector";
gsap.registerPlugin(ScrollTrigger);

const WhyUsCover = () => {
  const titleString =
    "Technology should be your success - machine, not your pain point.";
  const subtitleString = "Why choose us?";

  const whyUsCoverTextSectionRef = useRef();
  const whyUsCoverRef = useRef();
  const whyUsVectorFirst = useRef();
  const whyUsVectorSecond = useRef();
  const whyUsTitleRef = useRef();
  const whyUsCoverImgRef = useRef();
  const tl = useRef();

  let whyUsCoverVector1Animation = useRef();
  let whyUsCoverVector2Animation = useRef();

  useLayoutEffect(() => {
    if (window.innerWidth >= 1200) {
      whyUsCoverVector1Animation.current = gsap.fromTo(
        whyUsVectorFirst.current,
        {
          left: "50%",
          top: "50%",
          xPercent: -50,
          yPercent: -50,
        },
        {
          left: 0,
          top: 0,
          xPercent: -50,
          yPercent: -50,
          scrollTrigger: {
            trigger: whyUsCoverRef.current,
            start: "top top",
            end: "top top-=300",
            scrub: 2,
            markers: false,
          },
        }
      );
      whyUsCoverVector2Animation.current = gsap.fromTo(
        whyUsVectorSecond.current,
        {
          right: "50%",
          bottom: "50%",
          xPercent: 50,
          yPercent: 50,
        },
        {
          right: 0,
          bottom: 0,
          xPercent: 50,
          yPercent: 50,
          scrollTrigger: {
            trigger: whyUsCoverRef.current,
            start: "top top",
            end: "top top-=300",
            scrub: 2,
            markers: false,
          },
        }
      );

      tl.current = gsap
        .timeline()
        .fromTo(
          whyUsTitleRef.current,
          {
            opacity: 0,
            ease: "none",
          },
          {
            opacity: 1,
            yPercent: 0,
            scale: 1,
            top: 80,
          }
        )
        .from(whyUsCoverImgRef.current, { y: "100vh" })
        .fromTo(
          whyUsCoverImgRef.current,
          { clipPath: "circle(50% at 50% 130%)" },
          { clipPath: "circle(100% at 50% 130%)" }
        );

      ScrollTrigger.create({
        animation: tl.current,
        trigger: whyUsCoverRef.current,
        start: "top top",
        end: "top top-=1500",
        pin: whyUsCoverRef.current,
        scrub: 2,
        pinSpacing: true,
        markers: false,
      });
    }

    const fadeInUpHhyUsCoverTextAnimation = gsap.fromTo(
      `.WUC-tweenWrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          id: "Why us cover wrapper",
          trigger: whyUsCoverTextSectionRef.current,
          start: "top center",
          end: "top top",
          markers: false,
        },
      }
    );

    return () => {
      fadeInUpHhyUsCoverTextAnimation.kill();
      whyUsCoverVector1Animation.current.kill();
      whyUsCoverVector2Animation.current.kill();
    };
  }, []);

  return (
    <>
      <section
        ref={whyUsCoverRef}
        className={`es-section es-px-0 es-section__dark es-full-viewport ${whyUsCoverStyles.whyUsCoverSection}`}
      >
        <div
          className={`${whyUsCoverStyles.dark_section} ${whyUsCoverStyles.whyUsCoverContent}`}
        >
          <h2 ref={whyUsTitleRef}>Why choose us?</h2>

          <div className={whyUsCoverStyles.whyUsCoverMobileTitle}>
            <h2>Why Choose us?</h2>
          </div>

          <WhyUsMobileVector />
          <div className={whyUsCoverStyles.vectorsWrapper}>
            <svg
              ref={whyUsVectorFirst}
              width="586"
              height="661"
              viewBox="0 0 586 661"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M327.413 342.143C332.091 341.125 334.244 337.558 334.134 334.492C334.177 331.414 332.846 328.454 333.817 325.449C335.154 321.337 340.83 317.62 340.767 313.321C340.826 306.859 328.111 303.803 325.283 297.743C324.336 295.672 324.444 293.357 323.205 291.465C321.814 289.586 317.841 288.083 314.894 289.567C313.865 290.116 313.168 290.945 312.61 291.608C310.444 295.023 310.009 298.904 311.672 302.143C315.215 309.371 310.199 317.182 313.171 324.92C314.978 329.838 313.989 345.143 327.413 342.143Z"
                stroke="url(#paint0_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M352.946 315.501C353.817 313.12 354.078 310.791 352.952 308.428C350.522 303.411 342.703 300.086 337.53 297.149C333.161 294.605 328.333 292.099 325.702 288.328C325.179 287.605 324.808 286.868 324.424 285.979C323.093 283.018 323.262 279.622 320.876 276.906C318.298 273.746 310.571 271.49 304.929 273.82C304.777 273.833 304.777 273.833 304.637 273.999C302.719 274.933 301.438 276.119 300.474 277.432C296.874 282.047 295.503 287.545 297.489 292.755C297.694 293.352 297.733 293.81 297.633 294.434C297.491 298.136 294.296 302.1 294.458 305.776C294.81 313.433 298.798 320.469 299.938 328.365C300.446 332.472 301.755 336.971 302.799 341.955C303.354 344.828 300.225 349.555 301.39 352.376C303.912 358.462 312.568 360.792 319.833 361.243C322.301 361.338 324.895 361.115 327.616 360.573C335.777 358.948 341.282 353.247 340.831 346.214C340.563 341.318 339.267 336.971 340.896 332.68C341.501 330.783 342.73 328.986 344.265 327.163C347.599 323.033 351.443 319.474 352.946 315.501Z"
                stroke="url(#paint1_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M365.113 317.529C366.834 314.306 367.919 310.83 366.522 307.107C363.689 299.203 351.3 294.581 343.9 290.76C337.62 287.457 330.245 283.94 325.792 278.636C324.937 277.633 324.221 276.465 323.671 275.436C321.446 271.015 321.802 266.219 318.229 262.222C314.465 257.78 302.983 254.771 294.813 258.088C294.661 258.101 294.508 258.114 294.521 258.267C291.561 259.598 289.709 261.294 288.174 263.117C283.167 269.237 280.745 276.824 283.538 284.271C283.757 285.021 283.669 285.797 283.43 286.586C282.132 291.156 275.38 296.657 275.914 301.069C277.125 311.573 286.054 320.643 286.668 331.352C287.093 338.08 289.35 344.65 290.909 352.049C291.721 356.129 285.014 363.932 286.576 367.795C290.429 376.841 305.381 379.09 315.89 379.723C319.757 380.005 323.865 379.498 327.959 378.838C339.604 376.606 348.322 368.938 347.655 357.619C347.243 351.043 345.663 345.184 347.937 339.454C348.961 337.059 350.442 334.626 352.547 332.292C357.024 327.141 362.329 322.688 365.113 317.529Z"
                stroke="url(#paint2_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M377.279 319.556C379.85 315.492 381.745 310.716 380.257 305.925C376.883 295.3 360.063 289.215 350.573 284.343C342.37 280.13 332.323 275.921 326.338 268.903C325.151 267.622 324.257 266.162 323.363 264.701C320.256 258.973 320.938 252.611 316.027 247.345C310.937 241.788 295.853 238.013 284.976 242.024C284.823 242.037 284.684 242.203 284.531 242.216C280.516 243.791 277.953 246.164 276 248.484C269.445 256.274 266.113 265.785 269.7 275.315C270.084 276.205 269.718 277.312 269.352 278.42C266.913 284.011 256.729 290.729 257.494 296.044C259.552 309.242 273.13 320.526 273.536 334.173C273.879 343.522 277.235 352.149 279.323 362.116C280.393 367.405 270.094 378.13 272.219 383.174C277.25 395.194 298.651 397.349 312.251 398.177C317.505 398.494 322.999 398.021 328.44 396.938C343.57 394.098 356.111 384.41 354.479 369.023C353.604 360.643 352.211 353.383 355.143 346.367C356.419 343.336 358.319 340.405 361.135 337.395C366.448 331.25 373.213 325.902 377.279 319.556Z"
                stroke="url(#paint3_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M389.444 321.584C392.865 316.678 395.584 310.755 393.825 304.604C390.062 291.245 368.506 283.723 356.941 277.954C346.828 272.982 334.233 267.763 326.591 259.35C325.073 257.79 323.847 256.05 322.761 254.145C318.621 247.122 319.629 239.195 313.531 232.648C307.255 225.809 288.569 221.268 274.858 226.291C274.705 226.304 274.566 226.47 274.261 226.496C269.203 228.469 266.069 231.352 263.698 234.17C255.583 243.477 251.353 255.064 255.748 266.831C256.158 268.026 255.792 269.133 255.148 270.572C251.718 277.171 237.812 285.286 238.961 291.49C241.879 307.535 260.245 320.866 260.29 337.466C260.412 349.6 265.02 360.273 267.457 372.515C268.785 379.012 254.894 392.66 257.429 398.899C263.639 413.891 291.476 415.8 308.333 416.963C314.987 417.466 321.841 416.723 328.656 415.521C347.421 412.061 363.467 400.226 361.176 380.745C359.99 370.547 358.631 361.901 362.069 353.611C363.598 349.944 365.916 346.516 369.29 342.843C375.871 335.359 384.097 329.116 389.444 321.584Z"
                stroke="url(#paint4_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M401.61 323.612C406.02 317.698 409.41 310.642 407.559 303.422C403.241 287.19 377.268 278.356 363.474 271.703C351.438 265.821 336.17 259.909 326.845 249.797C325.148 247.944 323.591 245.926 322.325 243.729C317.304 235.398 318.639 225.906 311.189 217.938C303.739 209.97 281.286 204.523 264.893 210.546C264.741 210.559 264.449 210.738 264.296 210.751C258.197 213.12 254.492 216.514 251.55 219.842C242.026 230.654 236.759 244.483 241.949 258.333C242.372 259.681 242.033 261.093 240.943 262.725C236.359 270.191 218.882 279.689 220.415 286.783C224.345 305.662 247.194 321.067 247.171 340.44C247.058 355.208 252.766 367.938 255.884 382.736C257.47 390.44 239.986 407.011 242.932 414.444C250.473 432.397 284.606 434.225 304.541 435.43C312.582 435.968 320.809 435.106 328.984 433.634C351.233 429.567 370.797 415.738 367.846 392.163C366.35 380.146 365.026 370.114 369.108 360.385C370.903 356.233 373.627 352.155 377.571 347.972C385.447 339.454 394.981 332.33 401.61 323.612Z"
                stroke="url(#paint5_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M413.776 325.639C419.037 318.883 423.403 310.667 421.281 302.087C416.574 283.121 386.018 272.837 370.148 265.287C356.049 258.66 338.388 251.724 327.392 240.064C325.363 237.933 323.461 235.483 322.017 232.994C316.101 223.203 317.61 212.158 308.987 203.061C300.35 193.811 274.143 187.611 255.056 194.481C254.751 194.507 254.612 194.673 254.306 194.699C247.165 197.466 242.889 201.37 239.376 205.208C228.305 217.691 222.128 233.443 228.111 249.378C228.7 250.864 228.082 252.609 226.853 254.405C221.127 262.892 200.232 273.761 201.83 281.618C206.608 303.191 234.258 320.797 233.873 343.121C233.526 360.523 240.499 375.45 243.967 392.524C245.81 401.436 224.583 420.942 228.244 429.544C236.963 450.47 277.697 452.191 300.724 453.592C310.152 454.164 319.905 453.171 329.301 451.593C355.034 446.919 378.116 431.096 374.657 403.414C372.852 389.579 371.409 378.173 376.149 367.158C378.196 362.369 381.325 357.642 385.841 352.949C394.859 343.41 406.019 335.531 413.776 325.639Z"
                stroke="url(#paint6_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M425.942 327.667C432.053 320.069 437.229 310.553 434.863 300.918C429.753 279.066 394.64 267.637 376.529 259.049C360.507 251.511 340.312 243.718 327.645 230.511C325.285 228.1 323.205 225.358 321.416 222.438C314.619 211.339 316.454 198.728 306.492 188.363C296.669 177.832 266.707 170.879 244.939 178.748C244.647 178.927 244.342 178.953 244.05 179.132C236.006 182.131 231.019 186.711 227.088 191.046C214.609 205.034 207.382 222.874 214.173 241.046C214.788 242.837 214.183 244.734 212.662 246.71C205.959 256.357 181.315 268.317 183.31 277.216C188.948 301.636 221.234 321.303 220.641 346.566C220.059 366.602 228.285 383.573 232.281 403.215C234.382 413.334 209.575 435.916 213.621 445.407C223.671 469.294 270.688 470.781 296.82 472.529C307.647 473.289 318.774 472.177 329.669 470.163C358.885 464.882 385.625 446.899 381.354 415.136C379.391 399.47 377.83 386.691 383.228 374.389C385.528 368.964 389.075 363.739 394.149 358.383C404.283 347.518 416.903 338.745 425.942 327.667Z"
                stroke="url(#paint7_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M437.955 329.708C445.068 321.255 450.915 310.605 448.43 299.596C442.78 275.024 403.236 262.131 382.897 252.659C364.964 244.364 342.083 235.725 327.746 220.971C325.041 218.129 322.642 215.26 320.675 212.048C312.996 199.641 315.006 185.478 304.009 173.818C292.861 162.171 259.436 154.287 234.821 163.016C234.529 163.195 234.224 163.221 233.932 163.4C224.846 166.795 219.289 171.886 214.786 176.732C200.747 192.237 192.622 212.153 200.221 232.561C201.002 234.492 200.104 236.568 198.457 238.862C190.612 249.53 162.397 262.874 164.764 272.509C171.401 299.61 208.336 321.49 207.369 349.553C206.552 372.223 215.89 391.405 220.389 413.309C222.747 424.636 194.349 450.141 198.805 460.826C210.033 487.686 263.499 489.08 292.875 491.01C305.088 491.804 317.589 490.574 329.845 488.289C362.545 482.4 392.802 462.424 388.024 426.554C385.764 409.222 384.224 394.904 390.114 381.176C392.68 375.267 396.633 369.392 402.43 363.512C413.859 351.613 427.786 341.959 437.955 329.708Z"
                stroke="url(#paint8_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M450.121 331.735C458.084 322.441 464.743 310.491 462.013 298.427C455.96 270.968 411.847 256.778 389.432 246.409C369.576 237.202 344.161 227.706 328.153 211.404C325.117 208.283 322.526 204.97 320.227 201.478C311.667 187.764 314.003 172.036 301.668 159.107C289.333 146.179 252.154 137.541 225.01 147.257C224.718 147.436 224.26 147.475 223.968 147.654C213.841 151.446 207.56 157.06 202.639 162.403C187.052 179.58 178.03 201.572 186.41 223.911C187.204 225.995 186.333 228.376 184.393 230.849C175.406 242.537 143.76 257.098 146.358 267.636C153.855 297.584 195.426 321.525 194.251 352.527C193.213 377.983 203.79 399.057 208.817 423.529C211.433 436.064 179.443 464.491 184.461 476.358C196.868 506.191 256.77 507.338 289.237 509.464C302.837 510.292 316.711 508.944 330.34 506.541C366.677 500.032 400.3 478.075 395.001 437.944C392.43 418.795 390.925 403.09 397.473 388.075C400.292 381.529 404.663 375.158 411.018 368.615C423.284 355.722 438.672 345.173 450.121 331.735Z"
                stroke="url(#paint9_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M462.287 333.763C471.253 323.613 478.582 310.53 475.734 297.093C469.292 266.9 420.443 251.272 395.939 239.853C374.173 229.888 346.072 219.547 328.533 201.532C325.166 198.132 322.243 194.54 319.905 190.591C310.464 175.568 312.974 158.288 299.452 144.078C285.93 129.868 244.997 120.478 215.159 131.04C214.714 131.232 214.409 131.258 213.964 131.45C202.795 135.64 195.943 141.764 190.603 147.604C173.609 166.285 163.537 190.366 172.724 214.942C173.544 217.331 172.533 219.878 170.315 222.682C160.339 235.378 124.97 251.336 127.952 262.763C136.309 295.558 182.667 321.547 181.131 355.501C179.859 383.59 191.701 406.861 197.091 433.762C199.965 447.505 164.383 478.854 169.812 491.916C183.549 524.709 249.747 525.776 285.306 528.096C300.458 529.098 315.693 527.48 330.53 524.819C370.35 517.702 407.63 493.586 401.532 449.527C398.651 428.56 397.028 411.481 404.386 395.167C407.471 388.137 412.248 381.115 419.16 373.91C432.694 359.677 449.556 348.387 462.287 333.763Z"
                stroke="url(#paint10_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M474.453 335.791C484.268 324.799 492.561 310.403 489.469 295.911C482.485 262.997 429.205 245.906 402.473 233.602C378.784 222.727 348.149 211.528 328.787 191.979C325.241 188.287 321.987 184.416 319.304 180.035C308.969 163.552 311.818 144.859 296.957 129.381C282.262 114.042 237.561 103.746 205.042 115.307C204.597 115.499 204.153 115.691 203.695 115.731C191.483 120.318 184.06 126.952 178.15 133.303C159.595 153.502 148.625 179.658 158.62 206.471C159.606 208.999 158.469 211.864 155.958 214.848C144.84 228.565 105.887 245.753 109.101 258.083C118.457 293.559 169.312 321.774 167.554 358.514C166.048 389.237 178.989 414.566 185.047 443.869C188.026 458.833 149.005 493.092 154.844 507.348C169.76 543.114 242.407 544.087 281.21 546.59C297.748 547.626 314.369 546.042 330.567 543.111C373.87 535.386 414.669 509.276 408.05 460.958C405.012 438.159 403.283 419.86 411.121 401.967C414.458 394.3 419.653 386.781 427.137 379.065C442.118 363.786 460.44 351.601 474.453 335.791Z"
                stroke="url(#paint11_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M486.618 337.818C497.284 325.985 506.4 310.442 503.037 294.589C495.651 258.789 437.801 240.4 408.993 227.199C383.394 215.566 350.073 203.521 329.18 182.259C325.302 178.288 321.869 174.125 318.855 169.465C307.638 151.675 310.648 131.277 294.614 114.67C278.58 98.0626 230.43 86.9869 195.064 99.4086C194.619 99.6006 194.175 99.7926 193.717 99.832C180.616 104.803 172.469 111.961 166.001 118.975C146.052 140.831 134.018 168.924 144.82 197.973C145.833 200.806 144.543 203.685 141.906 206.986C129.646 221.724 87.2751 240.283 90.7068 253.362C100.923 291.686 156.566 321.948 154.448 361.641C152.707 394.997 166.914 422.523 173.334 454.255C176.571 470.426 133.971 507.76 140.36 523.045C156.607 561.772 235.536 562.512 277.444 565.362C295.383 566.585 313.364 564.73 331.074 561.515C377.861 553.183 422.33 525.066 414.9 472.667C411.551 448.05 409.857 428.364 418.353 409.184C422.095 400.868 427.556 392.865 435.598 384.486C451.694 367.881 471.324 354.815 486.618 337.818Z"
                stroke="url(#paint12_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M498.784 339.846C510.452 327.157 520.226 310.328 516.771 293.407C508.844 254.887 446.564 235.034 415.527 220.948C388.005 208.405 352.15 195.502 329.447 172.858C325.238 168.609 321.46 164.014 318.267 159.062C306.169 139.964 309.506 118 292.132 100.125C274.925 82.3888 223.008 70.4075 184.96 83.8286C184.515 84.0206 183.918 84.2257 183.474 84.4178C169.318 89.6332 160.6 97.3016 153.574 104.978C132.064 128.352 119.146 158.674 130.729 189.654C131.755 192.64 130.491 195.824 127.563 199.304C114.313 215.049 68.2055 234.852 72.0344 248.974C83.2502 289.978 143.39 322.467 141.063 365.099C139.101 401.242 154.546 430.659 161.482 464.807C164.977 482.185 118.785 522.442 125.584 538.922C143.009 580.622 228.388 581.268 273.526 584.147C292.851 585.405 312.206 583.432 331.29 580.098C381.56 571.159 429.535 540.896 421.61 484.542C417.951 458.107 416.138 437.047 425.292 416.581C429.301 407.781 435.153 398.976 443.919 390.073C461.118 371.99 482.208 358.029 498.784 339.846Z"
                stroke="url(#paint13_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M510.95 341.873C523.468 328.344 534.066 310.367 530.34 292.086C522.01 250.679 455.16 229.529 421.895 214.559C392.463 201.257 354.074 187.496 329.688 163.153C325.147 158.624 321.191 153.737 317.652 148.354C304.521 127.961 308.184 104.432 289.625 85.2748C271.231 66.2574 215.572 53.6756 174.83 67.9435C174.233 68.1487 173.788 68.3407 173.191 68.5459C157.993 74.1585 148.704 82.3372 141.259 90.511C118.189 115.403 104.374 147.8 116.765 181.017C117.956 184.143 116.414 187.658 113.345 191.304C98.9537 208.07 49.4284 229.243 53.4755 244.115C65.7039 287.953 130.327 322.516 127.779 367.933C125.581 406.71 142.14 438.338 149.591 474.901C153.343 493.487 103.407 536.68 110.769 554.341C129.372 599.014 221.2 599.566 269.43 602.641C290.141 603.933 311.022 601.828 331.313 598.237C385.22 588.678 436.56 556.433 427.962 495.833C424.006 467.732 422.215 445.134 432.014 423.229C436.275 413.792 442.546 404.489 451.869 394.924C470.529 375.946 493.093 361.244 510.95 341.873Z"
                stroke="url(#paint14_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M523.116 343.901C536.623 329.363 547.892 310.253 544.075 290.903C535.204 246.776 463.923 224.163 428.582 208.295C397.226 194.083 356.152 179.477 330.247 153.573C325.375 148.765 321.087 143.6 317.523 137.911C303.497 116.058 307.5 91.1156 287.601 70.6903C268.021 50.3914 208.607 37.0566 165.171 52.1714C164.574 52.3765 163.976 52.5817 163.532 52.7737C147.292 58.7835 137.432 67.4726 129.417 76.1567C104.952 102.706 90.0734 137.04 103.271 172.493C104.489 175.924 102.96 179.591 99.4466 183.43C83.9259 201.369 30.804 223.62 35.2352 239.381C48.3236 286.066 117.734 322.677 114.825 371.047C112.394 412.457 130.204 446.129 138.171 485.107C142.181 504.902 88.6525 551.017 96.5642 569.707C116.498 617.34 214.609 617.659 266.096 621.068C288.208 622.547 310.462 620.325 332.114 616.463C389.504 606.296 444.362 572.058 435.257 507.35C430.991 477.431 429.234 453.446 439.538 430.268C444.052 420.194 450.741 410.394 460.635 400.319C479.953 380.054 503.977 364.458 523.116 343.901Z"
                stroke="url(#paint15_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M332.15 634.754C393.011 623.828 451.54 587.582 441.775 518.78C435.24 473.222 434.3 440.865 468.765 405.461C489.529 384.149 515.014 367.659 535.281 345.929C549.639 330.549 561.732 310.292 557.643 289.582C548.383 242.721 472.366 218.67 434.949 201.905C395.444 184.089 341.435 165.677 316.756 127.215C301.848 104.054 306.025 77.56 284.94 55.8532C263.855 34.1464 199.427 19.7059 153.249 36.9015C135.967 43.3084 125.384 52.5209 116.95 61.7022C90.9243 89.7696 75.1484 126.179 88.988 163.729C96.2286 183.553 10.6299 214.288 16.3707 234.548C30.3059 283.927 104.365 322.752 101.248 374.06C98.5821 418.104 117.505 453.987 125.987 495.38C130.255 516.382 73.1479 565.573 81.4567 585.304C102.569 635.911 207.281 636.123 261.707 639.74C285.358 641.241 309.138 638.887 332.15 634.754Z"
                stroke="url(#paint16_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_517_3404"
                  x1="309.568"
                  y1="289.628"
                  x2="357.12"
                  y2="311.366"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_517_3404"
                  x1="291.737"
                  y1="274.384"
                  x2="378.266"
                  y2="323.692"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_517_3404"
                  x1="272.307"
                  y1="259.197"
                  x2="397.377"
                  y2="338.529"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_517_3404"
                  x1="252.996"
                  y1="243.85"
                  x2="416.014"
                  y2="353.019"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_517_3404"
                  x1="233.535"
                  y1="228.667"
                  x2="434.386"
                  y2="367.658"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_517_3404"
                  x1="214.074"
                  y1="213.5"
                  x2="452.331"
                  y2="382.469"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_517_3404"
                  x1="194.613"
                  y1="198.088"
                  x2="470.149"
                  y2="397.024"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_517_3404"
                  x1="175.151"
                  y1="182.905"
                  x2="488.388"
                  y2="411.511"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_517_3404"
                  x1="155.695"
                  y1="167.816"
                  x2="506.038"
                  y2="426.16"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_517_3404"
                  x1="136.396"
                  y1="152.619"
                  x2="523.883"
                  y2="440.521"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_517_3404"
                  x1="117.053"
                  y1="137.042"
                  x2="542.178"
                  y2="454.541"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_517_3404"
                  x1="97.2973"
                  y1="121.901"
                  x2="559.712"
                  y2="469.619"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint12_linear_517_3404"
                  x1="77.9906"
                  y1="106.629"
                  x2="577.862"
                  y2="483.813"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint13_linear_517_3404"
                  x1="58.3891"
                  y1="91.6277"
                  x2="595.568"
                  y2="498.811"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint14_linear_517_3404"
                  x1="38.9291"
                  y1="76.3517"
                  x2="613.287"
                  y2="513.22"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint15_linear_517_3404"
                  x1="19.7777"
                  y1="61.2201"
                  x2="631.245"
                  y2="527.584"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint16_linear_517_3404"
                  x1="0.000101007"
                  y1="45.8706"
                  x2="648.921"
                  y2="542.218"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              ref={whyUsVectorSecond}
              width="586"
              height="661"
              viewBox="0 0 586 661"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M327.413 342.143C332.091 341.125 334.244 337.558 334.134 334.492C334.177 331.414 332.846 328.454 333.817 325.449C335.154 321.337 340.83 317.62 340.767 313.321C340.826 306.859 328.111 303.803 325.283 297.743C324.336 295.672 324.444 293.357 323.205 291.465C321.814 289.586 317.841 288.083 314.894 289.567C313.865 290.116 313.168 290.945 312.61 291.608C310.444 295.023 310.009 298.904 311.672 302.143C315.215 309.371 310.199 317.182 313.171 324.92C314.978 329.838 313.989 345.143 327.413 342.143Z"
                stroke="url(#paint0_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M352.946 315.501C353.817 313.12 354.078 310.791 352.952 308.428C350.522 303.411 342.703 300.086 337.53 297.149C333.161 294.605 328.333 292.099 325.702 288.328C325.179 287.605 324.808 286.868 324.424 285.979C323.093 283.018 323.262 279.622 320.876 276.906C318.298 273.746 310.571 271.49 304.929 273.82C304.777 273.833 304.777 273.833 304.637 273.999C302.719 274.933 301.438 276.119 300.474 277.432C296.874 282.047 295.503 287.545 297.489 292.755C297.694 293.352 297.733 293.81 297.633 294.434C297.491 298.136 294.296 302.1 294.458 305.776C294.81 313.433 298.798 320.469 299.938 328.365C300.446 332.472 301.755 336.971 302.799 341.955C303.354 344.828 300.225 349.555 301.39 352.376C303.912 358.462 312.568 360.792 319.833 361.243C322.301 361.338 324.895 361.115 327.616 360.573C335.777 358.948 341.282 353.247 340.831 346.214C340.563 341.318 339.267 336.971 340.896 332.68C341.501 330.783 342.73 328.986 344.265 327.163C347.599 323.033 351.443 319.474 352.946 315.501Z"
                stroke="url(#paint1_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M365.113 317.529C366.834 314.306 367.919 310.83 366.522 307.107C363.689 299.203 351.3 294.581 343.9 290.76C337.62 287.457 330.245 283.94 325.792 278.636C324.937 277.633 324.221 276.465 323.671 275.436C321.446 271.015 321.802 266.219 318.229 262.222C314.465 257.78 302.983 254.771 294.813 258.088C294.661 258.101 294.508 258.114 294.521 258.267C291.561 259.598 289.709 261.294 288.174 263.117C283.167 269.237 280.745 276.824 283.538 284.271C283.757 285.021 283.669 285.797 283.43 286.586C282.132 291.156 275.38 296.657 275.914 301.069C277.125 311.573 286.054 320.643 286.668 331.352C287.093 338.08 289.35 344.65 290.909 352.049C291.721 356.129 285.014 363.932 286.576 367.795C290.429 376.841 305.381 379.09 315.89 379.723C319.757 380.005 323.865 379.498 327.959 378.838C339.604 376.606 348.322 368.938 347.655 357.619C347.243 351.043 345.663 345.184 347.937 339.454C348.961 337.059 350.442 334.626 352.547 332.292C357.024 327.141 362.329 322.688 365.113 317.529Z"
                stroke="url(#paint2_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M377.279 319.556C379.85 315.492 381.745 310.716 380.257 305.925C376.883 295.3 360.063 289.215 350.573 284.343C342.37 280.13 332.323 275.921 326.338 268.903C325.151 267.622 324.257 266.162 323.363 264.701C320.256 258.973 320.938 252.611 316.027 247.345C310.937 241.788 295.853 238.013 284.976 242.024C284.823 242.037 284.684 242.203 284.531 242.216C280.516 243.791 277.953 246.164 276 248.484C269.445 256.274 266.113 265.785 269.7 275.315C270.084 276.205 269.718 277.312 269.352 278.42C266.913 284.011 256.729 290.729 257.494 296.044C259.552 309.242 273.13 320.526 273.536 334.173C273.879 343.522 277.235 352.149 279.323 362.116C280.393 367.405 270.094 378.13 272.219 383.174C277.25 395.194 298.651 397.349 312.251 398.177C317.505 398.494 322.999 398.021 328.44 396.938C343.57 394.098 356.111 384.41 354.479 369.023C353.604 360.643 352.211 353.383 355.143 346.367C356.419 343.336 358.319 340.405 361.135 337.395C366.448 331.25 373.213 325.902 377.279 319.556Z"
                stroke="url(#paint3_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M389.444 321.584C392.865 316.678 395.584 310.755 393.825 304.604C390.062 291.245 368.506 283.723 356.941 277.954C346.828 272.982 334.233 267.763 326.591 259.35C325.073 257.79 323.847 256.05 322.761 254.145C318.621 247.122 319.629 239.195 313.531 232.648C307.255 225.809 288.569 221.268 274.858 226.291C274.705 226.304 274.566 226.47 274.261 226.496C269.203 228.469 266.069 231.352 263.698 234.17C255.583 243.477 251.353 255.064 255.748 266.831C256.158 268.026 255.792 269.133 255.148 270.572C251.718 277.171 237.812 285.286 238.961 291.49C241.879 307.535 260.245 320.866 260.29 337.466C260.412 349.6 265.02 360.273 267.457 372.515C268.785 379.012 254.894 392.66 257.429 398.899C263.639 413.891 291.476 415.8 308.333 416.963C314.987 417.466 321.841 416.723 328.656 415.521C347.421 412.061 363.467 400.226 361.176 380.745C359.99 370.547 358.631 361.901 362.069 353.611C363.598 349.944 365.916 346.516 369.29 342.843C375.871 335.359 384.097 329.116 389.444 321.584Z"
                stroke="url(#paint4_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M401.61 323.612C406.02 317.698 409.41 310.642 407.559 303.422C403.241 287.19 377.268 278.356 363.474 271.703C351.438 265.821 336.17 259.909 326.845 249.797C325.148 247.944 323.591 245.926 322.325 243.729C317.304 235.398 318.639 225.906 311.189 217.938C303.739 209.97 281.286 204.523 264.893 210.546C264.741 210.559 264.449 210.738 264.296 210.751C258.197 213.12 254.492 216.514 251.55 219.842C242.026 230.654 236.759 244.483 241.949 258.333C242.372 259.681 242.033 261.093 240.943 262.725C236.359 270.191 218.882 279.689 220.415 286.783C224.345 305.662 247.194 321.067 247.171 340.44C247.058 355.208 252.766 367.938 255.884 382.736C257.47 390.44 239.986 407.011 242.932 414.444C250.473 432.397 284.606 434.225 304.541 435.43C312.582 435.968 320.809 435.106 328.984 433.634C351.233 429.567 370.797 415.738 367.846 392.163C366.35 380.146 365.026 370.114 369.108 360.385C370.903 356.233 373.627 352.155 377.571 347.972C385.447 339.454 394.981 332.33 401.61 323.612Z"
                stroke="url(#paint5_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M413.776 325.639C419.037 318.883 423.403 310.667 421.281 302.087C416.574 283.121 386.018 272.837 370.148 265.287C356.049 258.66 338.388 251.724 327.392 240.064C325.363 237.933 323.461 235.483 322.017 232.994C316.101 223.203 317.61 212.158 308.987 203.061C300.35 193.811 274.143 187.611 255.056 194.481C254.751 194.507 254.612 194.673 254.306 194.699C247.165 197.466 242.889 201.37 239.376 205.208C228.305 217.691 222.128 233.443 228.111 249.378C228.7 250.864 228.082 252.609 226.853 254.405C221.127 262.892 200.232 273.761 201.83 281.618C206.608 303.191 234.258 320.797 233.873 343.121C233.526 360.523 240.499 375.45 243.967 392.524C245.81 401.436 224.583 420.942 228.244 429.544C236.963 450.47 277.697 452.191 300.724 453.592C310.152 454.164 319.905 453.171 329.301 451.593C355.034 446.919 378.116 431.096 374.657 403.414C372.852 389.579 371.409 378.173 376.149 367.158C378.196 362.369 381.325 357.642 385.841 352.949C394.859 343.41 406.019 335.531 413.776 325.639Z"
                stroke="url(#paint6_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M425.942 327.667C432.053 320.069 437.229 310.553 434.863 300.918C429.753 279.066 394.64 267.637 376.529 259.049C360.507 251.511 340.312 243.718 327.645 230.511C325.285 228.1 323.205 225.358 321.416 222.438C314.619 211.339 316.454 198.728 306.492 188.363C296.669 177.832 266.707 170.879 244.939 178.748C244.647 178.927 244.342 178.953 244.05 179.132C236.006 182.131 231.019 186.711 227.088 191.046C214.609 205.034 207.382 222.874 214.173 241.046C214.788 242.837 214.183 244.734 212.662 246.71C205.959 256.357 181.315 268.317 183.31 277.216C188.948 301.636 221.234 321.303 220.641 346.566C220.059 366.602 228.285 383.573 232.281 403.215C234.382 413.334 209.575 435.916 213.621 445.407C223.671 469.294 270.688 470.781 296.82 472.529C307.647 473.289 318.774 472.177 329.669 470.163C358.885 464.882 385.625 446.899 381.354 415.136C379.391 399.47 377.83 386.691 383.228 374.389C385.528 368.964 389.075 363.739 394.149 358.383C404.283 347.518 416.903 338.745 425.942 327.667Z"
                stroke="url(#paint7_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M437.955 329.708C445.068 321.255 450.915 310.605 448.43 299.596C442.78 275.024 403.236 262.131 382.897 252.659C364.964 244.364 342.083 235.725 327.746 220.971C325.041 218.129 322.642 215.26 320.675 212.048C312.996 199.641 315.006 185.478 304.009 173.818C292.861 162.171 259.436 154.287 234.821 163.016C234.529 163.195 234.224 163.221 233.932 163.4C224.846 166.795 219.289 171.886 214.786 176.732C200.747 192.237 192.622 212.153 200.221 232.561C201.002 234.492 200.104 236.568 198.457 238.862C190.612 249.53 162.397 262.874 164.764 272.509C171.401 299.61 208.336 321.49 207.369 349.553C206.552 372.223 215.89 391.405 220.389 413.309C222.747 424.636 194.349 450.141 198.805 460.826C210.033 487.686 263.499 489.08 292.875 491.01C305.088 491.804 317.589 490.574 329.845 488.289C362.545 482.4 392.802 462.424 388.024 426.554C385.764 409.222 384.224 394.904 390.114 381.176C392.68 375.267 396.633 369.392 402.43 363.512C413.859 351.613 427.786 341.959 437.955 329.708Z"
                stroke="url(#paint8_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M450.121 331.735C458.084 322.441 464.743 310.491 462.013 298.427C455.96 270.968 411.847 256.778 389.432 246.409C369.576 237.202 344.161 227.706 328.153 211.404C325.117 208.283 322.526 204.97 320.227 201.478C311.667 187.764 314.003 172.036 301.668 159.107C289.333 146.179 252.154 137.541 225.01 147.257C224.718 147.436 224.26 147.475 223.968 147.654C213.841 151.446 207.56 157.06 202.639 162.403C187.052 179.58 178.03 201.572 186.41 223.911C187.204 225.995 186.333 228.376 184.393 230.849C175.406 242.537 143.76 257.098 146.358 267.636C153.855 297.584 195.426 321.525 194.251 352.527C193.213 377.983 203.79 399.057 208.817 423.529C211.433 436.064 179.443 464.491 184.461 476.358C196.868 506.191 256.77 507.338 289.237 509.464C302.837 510.292 316.711 508.944 330.34 506.541C366.677 500.032 400.3 478.075 395.001 437.944C392.43 418.795 390.925 403.09 397.473 388.075C400.292 381.529 404.663 375.158 411.018 368.615C423.284 355.722 438.672 345.173 450.121 331.735Z"
                stroke="url(#paint9_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M462.287 333.763C471.253 323.613 478.582 310.53 475.734 297.093C469.292 266.9 420.443 251.272 395.939 239.853C374.173 229.888 346.072 219.547 328.533 201.532C325.166 198.132 322.243 194.54 319.905 190.591C310.464 175.568 312.974 158.288 299.452 144.078C285.93 129.868 244.997 120.478 215.159 131.04C214.714 131.232 214.409 131.258 213.964 131.45C202.795 135.64 195.943 141.764 190.603 147.604C173.609 166.285 163.537 190.366 172.724 214.942C173.544 217.331 172.533 219.878 170.315 222.682C160.339 235.378 124.97 251.336 127.952 262.763C136.309 295.558 182.667 321.547 181.131 355.501C179.859 383.59 191.701 406.861 197.091 433.762C199.965 447.505 164.383 478.854 169.812 491.916C183.549 524.709 249.747 525.776 285.306 528.096C300.458 529.098 315.693 527.48 330.53 524.819C370.35 517.702 407.63 493.586 401.532 449.527C398.651 428.56 397.028 411.481 404.386 395.167C407.471 388.137 412.248 381.115 419.16 373.91C432.694 359.677 449.556 348.387 462.287 333.763Z"
                stroke="url(#paint10_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M474.453 335.791C484.268 324.799 492.561 310.403 489.469 295.911C482.485 262.997 429.205 245.906 402.473 233.602C378.784 222.727 348.149 211.528 328.787 191.979C325.241 188.287 321.987 184.416 319.304 180.035C308.969 163.552 311.818 144.859 296.957 129.381C282.262 114.042 237.561 103.746 205.042 115.307C204.597 115.499 204.153 115.691 203.695 115.731C191.483 120.318 184.06 126.952 178.15 133.303C159.595 153.502 148.625 179.658 158.62 206.471C159.606 208.999 158.469 211.864 155.958 214.848C144.84 228.565 105.887 245.753 109.101 258.083C118.457 293.559 169.312 321.774 167.554 358.514C166.048 389.237 178.989 414.566 185.047 443.869C188.026 458.833 149.005 493.092 154.844 507.348C169.76 543.114 242.407 544.087 281.21 546.59C297.748 547.626 314.369 546.042 330.567 543.111C373.87 535.386 414.669 509.276 408.05 460.958C405.012 438.159 403.283 419.86 411.121 401.967C414.458 394.3 419.653 386.781 427.137 379.065C442.118 363.786 460.44 351.601 474.453 335.791Z"
                stroke="url(#paint11_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M486.618 337.818C497.284 325.985 506.4 310.442 503.037 294.589C495.651 258.789 437.801 240.4 408.993 227.199C383.394 215.566 350.073 203.521 329.18 182.259C325.302 178.288 321.869 174.125 318.855 169.465C307.638 151.675 310.648 131.277 294.614 114.67C278.58 98.0626 230.43 86.9869 195.064 99.4086C194.619 99.6006 194.175 99.7926 193.717 99.832C180.616 104.803 172.469 111.961 166.001 118.975C146.052 140.831 134.018 168.924 144.82 197.973C145.833 200.806 144.543 203.685 141.906 206.986C129.646 221.724 87.2751 240.283 90.7068 253.362C100.923 291.686 156.566 321.948 154.448 361.641C152.707 394.997 166.914 422.523 173.334 454.255C176.571 470.426 133.971 507.76 140.36 523.045C156.607 561.772 235.536 562.512 277.444 565.362C295.383 566.585 313.364 564.73 331.074 561.515C377.861 553.183 422.33 525.066 414.9 472.667C411.551 448.05 409.857 428.364 418.353 409.184C422.095 400.868 427.556 392.865 435.598 384.486C451.694 367.881 471.324 354.815 486.618 337.818Z"
                stroke="url(#paint12_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M498.784 339.846C510.452 327.157 520.226 310.328 516.771 293.407C508.844 254.887 446.564 235.034 415.527 220.948C388.005 208.405 352.15 195.502 329.447 172.858C325.238 168.609 321.46 164.014 318.267 159.062C306.169 139.964 309.506 118 292.132 100.125C274.925 82.3888 223.008 70.4075 184.96 83.8286C184.515 84.0206 183.918 84.2257 183.474 84.4178C169.318 89.6332 160.6 97.3016 153.574 104.978C132.064 128.352 119.146 158.674 130.729 189.654C131.755 192.64 130.491 195.824 127.563 199.304C114.313 215.049 68.2055 234.852 72.0344 248.974C83.2502 289.978 143.39 322.467 141.063 365.099C139.101 401.242 154.546 430.659 161.482 464.807C164.977 482.185 118.785 522.442 125.584 538.922C143.009 580.622 228.388 581.268 273.526 584.147C292.851 585.405 312.206 583.432 331.29 580.098C381.56 571.159 429.535 540.896 421.61 484.542C417.951 458.107 416.138 437.047 425.292 416.581C429.301 407.781 435.153 398.976 443.919 390.073C461.118 371.99 482.208 358.029 498.784 339.846Z"
                stroke="url(#paint13_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M510.95 341.873C523.468 328.344 534.066 310.367 530.34 292.086C522.01 250.679 455.16 229.529 421.895 214.559C392.463 201.257 354.074 187.496 329.688 163.153C325.147 158.624 321.191 153.737 317.652 148.354C304.521 127.961 308.184 104.432 289.625 85.2748C271.231 66.2574 215.572 53.6756 174.83 67.9435C174.233 68.1487 173.788 68.3407 173.191 68.5459C157.993 74.1585 148.704 82.3372 141.259 90.511C118.189 115.403 104.374 147.8 116.765 181.017C117.956 184.143 116.414 187.658 113.345 191.304C98.9537 208.07 49.4284 229.243 53.4755 244.115C65.7039 287.953 130.327 322.516 127.779 367.933C125.581 406.71 142.14 438.338 149.591 474.901C153.343 493.487 103.407 536.68 110.769 554.341C129.372 599.014 221.2 599.566 269.43 602.641C290.141 603.933 311.022 601.828 331.313 598.237C385.22 588.678 436.56 556.433 427.962 495.833C424.006 467.732 422.215 445.134 432.014 423.229C436.275 413.792 442.546 404.489 451.869 394.924C470.529 375.946 493.093 361.244 510.95 341.873Z"
                stroke="url(#paint14_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M523.116 343.901C536.623 329.363 547.892 310.253 544.075 290.903C535.204 246.776 463.923 224.163 428.582 208.295C397.226 194.083 356.152 179.477 330.247 153.573C325.375 148.765 321.087 143.6 317.523 137.911C303.497 116.058 307.5 91.1156 287.601 70.6903C268.021 50.3914 208.607 37.0566 165.171 52.1714C164.574 52.3765 163.976 52.5817 163.532 52.7737C147.292 58.7835 137.432 67.4726 129.417 76.1567C104.952 102.706 90.0734 137.04 103.271 172.493C104.489 175.924 102.96 179.591 99.4466 183.43C83.9259 201.369 30.804 223.62 35.2352 239.381C48.3236 286.066 117.734 322.677 114.825 371.047C112.394 412.457 130.204 446.129 138.171 485.107C142.181 504.902 88.6525 551.017 96.5642 569.707C116.498 617.34 214.609 617.659 266.096 621.068C288.208 622.547 310.462 620.325 332.114 616.463C389.504 606.296 444.362 572.058 435.257 507.35C430.991 477.431 429.234 453.446 439.538 430.268C444.052 420.194 450.741 410.394 460.635 400.319C479.953 380.054 503.977 364.458 523.116 343.901Z"
                stroke="url(#paint15_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <path
                d="M332.15 634.754C393.011 623.828 451.54 587.582 441.775 518.78C435.24 473.222 434.3 440.865 468.765 405.461C489.529 384.149 515.014 367.659 535.281 345.929C549.639 330.549 561.732 310.292 557.643 289.582C548.383 242.721 472.366 218.67 434.949 201.905C395.444 184.089 341.435 165.677 316.756 127.215C301.848 104.054 306.025 77.56 284.94 55.8532C263.855 34.1464 199.427 19.7059 153.249 36.9015C135.967 43.3084 125.384 52.5209 116.95 61.7022C90.9243 89.7696 75.1484 126.179 88.988 163.729C96.2286 183.553 10.6299 214.288 16.3707 234.548C30.3059 283.927 104.365 322.752 101.248 374.06C98.5821 418.104 117.505 453.987 125.987 495.38C130.255 516.382 73.1479 565.573 81.4567 585.304C102.569 635.911 207.281 636.123 261.707 639.74C285.358 641.241 309.138 638.887 332.15 634.754Z"
                stroke="url(#paint16_linear_517_3404)"
                strokeWidth="1.5"
                strokeMiterlimit="2.61"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_517_3404"
                  x1="309.568"
                  y1="289.628"
                  x2="357.12"
                  y2="311.366"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_517_3404"
                  x1="291.737"
                  y1="274.384"
                  x2="378.266"
                  y2="323.692"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_517_3404"
                  x1="272.307"
                  y1="259.197"
                  x2="397.377"
                  y2="338.529"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_517_3404"
                  x1="252.996"
                  y1="243.85"
                  x2="416.014"
                  y2="353.019"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_517_3404"
                  x1="233.535"
                  y1="228.667"
                  x2="434.386"
                  y2="367.658"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_517_3404"
                  x1="214.074"
                  y1="213.5"
                  x2="452.331"
                  y2="382.469"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_517_3404"
                  x1="194.613"
                  y1="198.088"
                  x2="470.149"
                  y2="397.024"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_517_3404"
                  x1="175.151"
                  y1="182.905"
                  x2="488.388"
                  y2="411.511"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_517_3404"
                  x1="155.695"
                  y1="167.816"
                  x2="506.038"
                  y2="426.16"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_517_3404"
                  x1="136.396"
                  y1="152.619"
                  x2="523.883"
                  y2="440.521"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint10_linear_517_3404"
                  x1="117.053"
                  y1="137.042"
                  x2="542.178"
                  y2="454.541"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint11_linear_517_3404"
                  x1="97.2973"
                  y1="121.901"
                  x2="559.712"
                  y2="469.619"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint12_linear_517_3404"
                  x1="77.9906"
                  y1="106.629"
                  x2="577.862"
                  y2="483.813"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint13_linear_517_3404"
                  x1="58.3891"
                  y1="91.6277"
                  x2="595.568"
                  y2="498.811"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint14_linear_517_3404"
                  x1="38.9291"
                  y1="76.3517"
                  x2="613.287"
                  y2="513.22"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint15_linear_517_3404"
                  x1="19.7777"
                  y1="61.2201"
                  x2="631.245"
                  y2="527.584"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
                <linearGradient
                  id="paint16_linear_517_3404"
                  x1="0.000101007"
                  y1="45.8706"
                  x2="648.921"
                  y2="542.218"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.197816" stopColor="#0066CC" />
                  <stop offset="0.513542" stopColor="#EB3300" />
                  <stop offset="1" stopColor="#EB8D00" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            ref={whyUsCoverImgRef}
            className={whyUsCoverStyles.whyUsCoverImg}
          >
            <img src={whyUsCoverImg} alt="why Us Cover Img" />
          </div>
        </div>
      </section>
      <section
        ref={whyUsCoverTextSectionRef}
        className={`es-section es-px-0 es-pt-0 es-section__dark`}
      >
        <div className={whyUsCoverStyles.whyUsCoverText}>
          <div
            className={`WUC-tweenWrapper ${whyUsCoverStyles.whyUsCoverTextContent}`}
          >
            <span>{subtitleString}</span>
            <div className={whyUsCoverStyles.whyUsRow}>
              <div className={`${whyUsCoverStyles.whyUsCol}`}>
                <StarVector />
              </div>
              <div className={whyUsCoverStyles.whyUsCol}>
                <h3>{titleString}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUsCover;
