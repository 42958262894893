import React from "react";
import * as mouseIndicatorStyles from "./mouseIndicator.module.scss";

const MouseIndicator = () => {
  return (
    <div className={mouseIndicatorStyles.mouseIndicatorStylesWrapper}>
      <svg
        width="26"
        height="34"
        viewBox="0 0 26 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="24"
          height="32"
          rx="5"
          stroke="white"
          strokeWidth="2"
        />
        <line
          x1="13"
          y1="7"
          x2="13"
          y2="13"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>

      <span>scroll</span>
    </div>
  );
};

export default MouseIndicator;
