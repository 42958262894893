import React from "react";
import * as whyUsMobileVectorStyles from "./WhyUsMobileVector.module.scss";
import mobileVector from "../../../src/assets/images/mobileVector.svg";

const WhyUsMobileVector = () => {
  return (
    <div className={whyUsMobileVectorStyles.vectorsMobileWrapper}>
      <img loading="lazy" src={mobileVector} alt="mobile vector" />
      {/* <svg
        width="331"
        height="315"
        viewBox="0 0 331 315"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M167.251 173.991C168.447 175.559 170.154 175.74 171.279 175.163C172.435 174.641 173.307 173.628 174.598 173.465C176.366 173.246 178.744 174.715 180.337 173.941C182.759 172.834 181.679 167.556 183.446 165.442C184.053 164.727 184.936 164.363 185.426 163.571C185.884 162.723 185.751 160.978 184.683 160.138C184.298 159.85 183.867 159.734 183.522 159.642C181.87 159.43 180.346 159.946 179.427 161.132C177.349 163.716 173.558 163.209 171.19 165.669C169.67 167.202 163.787 169.506 167.251 173.991Z"
          stroke="url(#paint0_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M181.65 178.866C182.69 178.775 183.605 178.466 184.29 177.633C185.738 175.85 185.613 172.351 185.805 169.909C185.992 167.833 186.083 165.595 187.031 163.954C187.21 163.633 187.42 163.366 187.685 163.067C188.557 162.053 189.854 161.523 190.45 160.159C191.179 158.645 190.672 155.367 188.817 153.669C188.785 153.615 188.785 153.615 188.699 153.592C188.016 153.039 187.349 152.768 186.691 152.638C184.341 152.1 182.049 152.549 180.452 154.2C180.265 154.381 180.101 154.475 179.851 154.547C178.445 155.14 176.407 154.64 175.064 155.343C172.269 156.811 170.339 159.528 167.592 161.333C166.148 162.239 164.698 163.514 163.021 164.774C162.046 165.482 159.735 165.14 158.886 166.068C157.056 168.072 157.698 171.709 158.798 174.498C159.194 175.436 159.73 176.365 160.408 177.285C162.439 180.047 165.528 181.106 168.073 179.709C169.854 178.754 171.249 177.511 173.135 177.37C173.949 177.264 174.834 177.409 175.782 177.663C177.905 178.186 179.905 178.999 181.65 178.866Z"
          stroke="url(#paint1_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M183.018 183.76C184.521 183.84 186.008 183.637 187.153 182.466C189.608 180.029 189.169 174.598 189.302 171.169C189.438 168.249 189.462 164.884 190.664 162.295C190.888 161.801 191.199 161.33 191.487 160.945C192.748 159.343 194.6 158.638 195.468 156.607C196.468 154.427 195.585 149.617 192.921 147.147C192.889 147.093 192.858 147.038 192.803 147.07C191.79 146.198 190.833 145.803 189.885 145.549C186.727 144.749 183.472 145.17 181.182 147.513C180.94 147.725 180.635 147.828 180.299 147.877C178.367 148.191 175.135 146.632 173.582 147.601C169.874 149.888 168.049 154.804 164.16 156.903C161.724 158.237 159.667 160.226 157.178 162.1C155.797 163.116 151.714 161.976 150.546 163.233C147.843 166.251 149.615 172.223 151.214 176.255C151.784 177.747 152.691 179.191 153.652 180.603C156.519 184.559 160.903 186.473 165.01 184.247C167.391 182.945 169.302 181.332 171.837 181.18C172.909 181.144 174.076 181.272 175.315 181.65C178.018 182.42 180.607 183.622 183.018 183.76Z"
          stroke="url(#paint2_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M184.386 188.654C186.352 188.904 188.465 188.777 189.993 187.385C193.368 184.27 192.701 176.931 192.862 172.539C193.001 168.743 192.817 164.258 194.391 160.8C194.662 160.133 195.05 159.544 195.439 158.956C197.034 156.796 199.527 155.94 200.634 153.187C201.819 150.317 200.593 144.03 197.197 140.671C197.165 140.617 197.079 140.593 197.048 140.539C195.759 139.316 194.426 138.774 193.219 138.45C189.167 137.365 185.036 137.782 182.107 140.785C181.842 141.084 181.365 141.141 180.887 141.198C178.375 141.264 174.09 138.637 172.24 139.851C167.675 142.924 165.836 149.961 160.814 152.496C157.386 154.256 154.752 157.016 151.398 159.535C149.611 160.858 143.811 158.888 142.3 160.562C138.693 164.539 141.627 172.901 143.693 178.12C144.492 180.136 145.628 182.103 146.983 183.944C150.684 189.094 156.49 192.081 161.946 188.785C164.92 186.995 167.386 185.208 170.516 185.076C171.87 185.023 173.295 185.22 174.91 185.746C178.131 186.655 181.308 188.245 184.386 188.654Z"
          stroke="url(#paint3_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M185.755 193.547C188.183 193.967 190.868 193.947 192.856 192.216C197.183 188.479 196.226 179.122 196.359 173.799C196.448 169.157 196.196 163.546 198 159.225C198.317 158.386 198.752 157.625 199.273 156.887C201.171 154.116 204.305 153.108 205.683 149.689C207.139 146.152 205.57 138.387 201.301 134.148C201.27 134.093 201.183 134.07 201.12 133.961C199.501 132.418 197.878 131.752 196.412 131.36C191.522 129.957 186.46 130.402 182.836 134.097C182.462 134.459 181.985 134.516 181.336 134.527C178.275 134.399 172.818 130.627 170.703 132.14C165.226 136.031 163.459 145.212 157.273 148.128C152.766 150.292 149.589 153.876 145.446 156.923C143.254 158.553 135.735 155.753 133.85 157.789C129.34 162.725 133.489 173.445 135.999 179.938C136.973 182.509 138.448 184.937 140.086 187.27C144.655 193.668 151.873 197.588 158.742 193.331C162.34 191.108 165.329 189.091 169.023 188.926C170.659 188.856 172.343 189.122 174.302 189.74C178.244 190.888 182.01 192.867 185.755 193.547Z"
          stroke="url(#paint4_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M187.122 198.441C190.099 199.054 193.324 199.087 195.694 197.135C200.997 192.689 199.757 181.455 199.831 175.146C199.924 169.627 199.463 162.898 201.608 157.652C202.003 156.695 202.484 155.762 203.083 154.906C205.315 151.577 209.09 150.418 210.762 146.246C212.434 142.075 210.546 132.745 205.435 127.68C205.404 127.626 205.286 127.548 205.254 127.493C203.305 125.631 201.392 124.841 199.636 124.325C193.938 122.659 187.859 123.109 183.596 127.464C183.168 127.858 182.581 127.978 181.782 127.856C178.196 127.449 171.599 122.587 169.22 124.397C162.862 129.161 161.104 140.378 153.871 143.752C148.341 146.289 144.587 150.642 139.61 154.39C137.012 156.327 127.776 152.697 125.516 155.095C120.134 161.044 125.413 174.1 128.445 181.749C129.649 184.843 131.407 187.763 133.384 190.556C138.787 198.148 147.364 203.033 155.646 197.814C159.868 195.158 163.381 192.911 167.724 192.736C169.587 192.68 171.584 192.985 173.834 193.726C178.388 195.177 182.711 197.49 187.122 198.441Z"
          stroke="url(#paint5_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M188.491 203.335C191.93 204.118 195.759 204.312 198.59 202.022C204.845 196.953 203.346 183.755 203.392 176.515C203.402 170.097 202.906 162.295 205.336 156.156C205.777 155.027 206.359 153.889 207.036 152.915C209.656 148.998 214.041 147.632 215.93 142.826C217.873 137.988 215.609 127.126 209.712 121.203C209.649 121.094 209.563 121.071 209.5 120.962C207.22 118.78 205.017 117.866 202.971 117.226C196.38 115.275 189.423 115.721 184.522 120.736C184.071 121.216 183.312 121.29 182.427 121.145C178.26 120.49 170.555 114.592 167.902 116.56C160.687 122.111 158.946 135.503 150.549 139.258C143.995 142.168 139.642 147.376 133.877 151.652C130.874 153.896 119.888 149.382 117.318 152.25C111.032 159.159 117.503 174.659 121.002 183.496C122.435 187.113 124.509 190.579 126.739 193.81C132.977 202.596 142.912 208.445 152.638 202.32C157.485 199.23 161.489 196.7 166.427 196.545C168.571 196.473 170.882 196.815 173.422 197.68C178.556 199.379 183.445 202.167 188.491 203.335Z"
          stroke="url(#paint6_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M189.859 208.228C193.761 209.182 198.216 209.451 201.398 206.886C208.66 201.163 206.793 186.065 206.834 177.807C206.848 170.512 206.23 161.615 208.946 154.582C209.433 153.281 210.093 152.025 210.87 150.848C213.825 146.373 218.851 144.856 220.979 139.328C223.193 133.823 220.555 121.429 213.817 114.681C213.699 114.603 213.636 114.494 213.518 114.416C210.994 111.938 208.415 110.877 206.11 110.167C198.711 107.954 190.792 108.373 185.198 114.08C184.637 114.623 183.823 114.728 182.82 114.506C178.05 113.689 169.283 106.582 166.311 108.881C158.183 115.249 156.483 130.731 146.953 134.922C139.375 138.204 134.479 144.237 127.847 149.159C124.438 151.71 111.68 146.397 108.845 149.564C101.687 157.486 109.343 175.29 113.254 185.346C114.862 189.519 117.22 193.477 119.874 197.191C126.947 207.17 138.327 214.007 149.434 206.867C154.936 203.398 159.432 200.584 164.965 200.449C167.391 200.36 169.961 200.772 172.845 201.729C178.669 203.613 184.147 206.79 189.859 208.228Z"
          stroke="url(#paint7_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M191.195 213.067C195.591 214.245 200.586 214.568 204.26 211.718C212.442 205.318 210.347 188.311 210.33 179.067C210.293 170.927 209.521 160.88 212.522 152.954C213.111 151.448 213.762 150.052 214.617 148.757C217.906 143.725 223.541 142.001 225.972 135.862C228.371 129.668 225.475 115.819 217.919 108.158C217.802 108.08 217.739 107.971 217.621 107.893C214.767 105.096 211.897 103.911 209.303 103.077C201.065 100.547 192.214 100.993 185.926 107.392C185.342 108.021 184.411 108.049 183.267 107.835C177.916 106.771 168.01 98.5727 164.828 101.139C155.874 108.348 154.16 125.951 143.52 130.492C134.918 134.146 129.391 140.98 122.003 146.484C118.189 149.343 103.712 143.2 100.503 146.729C92.4416 155.609 101.259 175.804 105.669 187.102C107.506 191.798 110.148 196.248 113.141 200.422C121.049 211.595 133.787 219.397 146.337 211.35C152.41 207.479 157.484 204.404 163.635 204.205C166.288 204.13 169.17 204.579 172.377 205.715C178.813 207.902 184.847 211.413 191.195 213.067Z"
          stroke="url(#paint8_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M192.564 217.962C197.423 219.31 203.044 219.708 207.069 216.582C216.258 209.528 213.849 190.589 213.804 180.414C213.772 171.397 212.877 160.255 216.164 151.435C216.798 149.757 217.582 148.212 218.483 146.744C222.106 141.155 228.383 139.28 231.053 132.419C233.722 125.559 230.453 110.177 222.087 101.745C221.969 101.668 221.875 101.504 221.757 101.426C218.573 98.3091 215.381 96.9458 212.528 96.0427C203.397 93.2264 193.615 93.7005 186.743 100.729C186.104 101.389 185.063 101.48 183.802 101.188C177.871 99.8758 166.91 90.6102 163.432 93.42C153.566 101.448 151.893 121.141 140.12 126.116C130.44 130.175 124.423 137.802 116.169 143.952C111.949 147.117 95.7548 140.144 92.2032 144.09C83.2379 153.929 93.271 176.482 98.1482 188.968C100.214 194.187 103.14 199.129 106.417 203.795C115.192 216.217 129.257 224.928 143.306 215.943C150.003 211.639 155.6 208.335 162.346 208.156C165.281 208.065 168.422 208.583 171.973 209.812C178.926 212.137 185.55 216.036 192.564 217.962Z"
          stroke="url(#paint9_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M193.93 222.855C199.283 224.428 205.445 224.878 209.962 221.469C220.103 213.792 217.403 192.835 217.385 181.697C217.302 171.835 216.253 159.543 219.912 149.852C220.593 148.002 221.423 146.284 222.488 144.722C226.445 138.576 233.331 136.494 236.272 128.967C239.213 121.44 235.568 104.526 226.416 95.2366C226.267 95.1042 226.204 94.9949 226.055 94.8625C222.54 91.4263 219.059 89.939 215.947 88.9666C206.008 85.8878 195.264 86.3349 187.698 94.0548C186.949 94.7783 185.823 94.8459 184.389 94.5079C177.909 93.0026 165.777 82.5917 162.034 85.7001C151.256 94.5459 149.655 116.383 136.717 121.74C126.014 126.171 119.398 134.653 110.302 141.363C105.676 144.835 87.7635 137.033 83.8378 141.34C74.0004 152.193 85.1632 177.081 90.5075 190.754C92.7798 196.583 96.0443 201.985 99.6283 207.057C109.238 220.673 124.748 230.372 140.123 220.402C147.444 215.665 153.533 212.077 160.906 211.973C164.068 211.897 167.522 212.453 171.418 213.774C179.092 216.338 186.25 220.658 193.93 222.855Z"
          stroke="url(#paint10_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M195.298 227.749C201.114 229.492 207.934 230.072 212.801 226.387C223.863 218.033 220.936 195.167 220.858 183.043C220.779 172.305 219.608 158.918 223.521 148.279C224.28 146.311 225.156 144.421 226.322 142.654C230.667 135.919 238.14 133.718 241.321 125.469C244.478 117.307 240.513 98.8292 230.52 88.714C230.37 88.5816 230.221 88.4493 230.127 88.2854C226.282 84.5298 222.51 82.9184 219.108 81.8221C208.331 78.4262 196.655 78.9009 188.395 87.3127C187.624 88.1224 186.356 88.1984 184.805 87.7827C177.745 86.0295 164.528 74.4963 160.488 77.8487C148.885 87.5356 147.238 111.439 133.221 117.2C121.494 122.003 114.303 131.256 104.427 138.634C99.3635 142.359 79.7651 133.781 75.4653 138.45C64.7242 150.262 77.0483 177.539 82.8911 192.456C85.3928 198.808 88.8867 204.733 92.8092 210.265C103.254 225.075 120.122 235.739 136.996 224.831C144.973 219.715 151.499 215.874 159.544 215.674C162.988 215.58 166.701 216.206 170.887 217.651C179.205 220.573 186.951 225.281 195.298 227.749Z"
          stroke="url(#paint11_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M196.668 232.642C202.946 234.555 210.338 235.243 215.666 231.219C227.735 222.211 224.493 197.413 224.388 184.358C224.258 172.774 222.934 158.237 227.219 146.728C228.023 144.588 228.977 142.58 230.189 140.641C234.869 133.349 243.006 130.909 246.403 122.026C249.799 113.143 245.523 93.2415 234.712 82.2142C234.562 82.0818 234.413 81.9494 234.318 81.7854C230.175 77.765 226.082 75.9752 222.335 74.7865C210.695 71.1595 198.111 71.5756 189.158 80.6794C188.278 81.5521 186.978 81.5736 185.286 81.1663C177.646 79.1652 163.321 66.5959 159.039 70.1607C146.523 80.6657 144.949 106.714 129.768 112.855C117.017 118.031 109.227 128.139 98.5077 136.077C93.0388 140.109 71.668 130.733 67.0802 135.786C55.467 148.612 68.9754 178.193 75.2307 194.329C77.9071 201.236 81.7395 207.622 86.0322 213.669C97.312 229.672 115.57 241.355 133.825 229.431C142.426 223.882 149.476 219.812 158.116 219.633C161.873 219.577 165.813 220.217 170.344 221.754C179.351 224.861 187.655 229.904 196.668 232.642Z"
          stroke="url(#paint12_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M198.036 237.536C204.808 239.674 212.795 240.382 218.506 236.138C231.495 226.452 228.026 199.746 227.861 185.705C227.736 173.244 226.288 157.612 230.773 145.186C231.623 142.873 232.678 140.661 233.968 138.605C238.982 130.755 247.76 128.164 251.396 118.56C255.009 109.042 250.413 87.5764 238.76 75.7231C238.611 75.5907 238.43 75.4037 238.281 75.2713C233.862 70.8999 229.479 68.9861 225.387 67.705C212.909 63.7609 199.338 64.2361 189.801 73.9687C188.866 74.8729 187.457 74.9575 185.647 74.4725C177.458 72.2782 162.016 58.5319 157.416 62.4268C144.074 73.7731 142.454 101.888 126.14 108.465C112.31 114.045 104.031 124.946 92.5003 133.497C86.6257 137.836 63.537 127.631 58.5752 133.046C46.0583 146.83 60.7279 178.802 67.5365 196.148C70.4422 203.578 74.5586 210.456 79.1353 216.995C91.2501 234.192 110.921 246.808 130.565 234.009C139.79 228.027 147.332 223.673 156.567 223.514C160.551 223.473 164.859 224.119 169.712 225.835C179.463 229.095 188.356 234.527 198.036 237.536Z"
          stroke="url(#paint13_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M199.403 242.43C206.637 244.738 215.196 245.553 221.367 240.971C235.363 230.631 231.58 201.993 231.357 186.965C231.18 173.66 229.611 156.932 234.435 143.581C235.332 141.096 236.465 138.766 237.856 136.506C243.172 128.044 252.591 125.302 256.498 115.031C260.382 104.846 255.357 81.8801 242.917 69.1693C242.737 68.9823 242.587 68.8499 242.406 68.6628C237.658 63.972 232.984 61.9343 228.634 60.5839C215.316 56.3226 200.815 56.8255 190.584 67.2499C189.626 68.2404 188.045 68.2787 186.148 67.7706C177.379 65.3284 160.829 50.5461 155.987 54.6534C141.764 66.8724 140.153 97.0221 122.761 104.003C107.908 109.955 98.9976 121.658 86.6558 130.823C80.3756 135.469 55.5375 124.379 50.2332 130.211C36.8125 144.954 52.6434 179.316 59.9191 197.849C63.0541 205.803 67.4861 213.227 72.3698 220.172C85.3512 238.617 106.349 252.143 127.46 238.352C137.255 231.968 145.375 227.353 155.26 227.184C159.525 227.126 164.092 227.841 169.289 229.649C179.63 233.298 189.056 239.15 199.403 242.43Z"
          stroke="url(#paint14_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M200.771 247.324C208.555 249.825 217.654 250.693 224.207 245.889C239.124 234.872 235.114 204.325 234.862 188.366C234.69 174.184 232.966 156.307 238.108 142.117C239.051 139.459 240.23 136.957 241.73 134.633C247.435 125.583 257.441 122.721 261.588 111.729C265.743 100.877 260.342 76.3787 247.117 62.8104C246.936 62.6234 246.755 62.4363 246.605 62.3039C241.527 57.2936 236.563 55.1319 231.922 53.6576C217.743 49.1653 202.333 49.6097 191.409 60.7259C190.341 61.7795 188.706 61.8494 186.66 61.2088C177.255 58.5503 159.675 42.6142 154.568 47.0202C139.433 60.0573 137.894 92.3512 119.337 99.7132C103.461 106.038 94.0065 118.564 80.8534 128.344C74.1676 133.297 47.6116 121.377 42.0194 127.593C27.7266 143.351 44.7418 180.016 52.4615 199.823C55.7712 208.332 60.4871 216.248 65.7095 223.653C79.5259 243.293 101.882 257.783 124.437 243.085C134.856 236.268 143.499 231.423 153.948 231.22C158.495 231.145 163.32 231.93 168.808 233.862C179.743 237.532 189.758 243.772 200.771 247.324Z"
          stroke="url(#paint15_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <path
          d="M58.8907 226.861C73.5967 247.663 97.343 263.172 121.311 247.513C137.169 237.118 149.079 231.117 168.309 237.794C179.887 241.82 190.491 248.45 202.139 252.217C210.386 254.889 220.057 255.863 227.07 250.721C242.939 239.082 238.638 206.516 238.359 189.626C238.107 171.774 235.545 148.405 245.587 132.479C251.626 122.872 262.242 119.803 266.659 108.144C271.077 96.4857 265.213 69.9229 250.732 55.6949C245.323 50.3652 240.038 48.0249 235.139 46.4813C220.12 41.6718 203.779 42.1437 192.185 53.8657C186.052 60.0295 159.634 33.4565 153.077 39.1369C137.085 52.9605 135.532 87.3757 115.842 95.1732C98.9416 101.87 88.8569 115.198 74.8925 125.592C67.801 130.852 39.4725 118.134 33.5608 124.68C18.3644 141.396 36.5724 180.506 44.7277 201.447C48.2982 210.534 53.3297 218.996 58.8907 226.861Z"
          stroke="url(#paint16_linear_580_4633)"
          strokeWidth="1.5"
          strokeMiterlimit="2.61"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_580_4633"
            x1="183.729"
            y1="158.161"
            x2="183.923"
            y2="179.701"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_580_4633"
            x1="186.303"
            y1="148.845"
            x2="183.015"
            y2="189.744"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_580_4633"
            x1="188.576"
            y1="138.943"
            x2="180.817"
            y2="199.467"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_580_4633"
            x1="190.93"
            y1="129.057"
            x2="178.665"
            y2="208.952"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_580_4633"
            x1="193.198"
            y1="119.144"
            x2="176.411"
            y2="218.363"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_580_4633"
            x1="195.458"
            y1="109.233"
            x2="174.017"
            y2="227.647"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_580_4633"
            x1="197.811"
            y1="99.2792"
            x2="171.699"
            y2="236.837"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_580_4633"
            x1="200.078"
            y1="89.3655"
            x2="169.479"
            y2="246.173"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_580_4633"
            x1="202.31"
            y1="79.4707"
            x2="167.095"
            y2="255.317"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_580_4633"
            x1="204.61"
            y1="69.6159"
            x2="164.853"
            y2="264.484"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_580_4633"
            x1="207.043"
            y1="59.6771"
            x2="162.815"
            y2="273.759"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_580_4633"
            x1="209.243"
            y1="49.6612"
            x2="160.25"
            y2="282.935"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_580_4633"
            x1="211.571"
            y1="39.7898"
            x2="158.126"
            y2="292.186"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_580_4633"
            x1="213.745"
            y1="29.8559"
            x2="155.621"
            y2="301.412"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_580_4633"
            x1="216.046"
            y1="19.9271"
            x2="153.338"
            y2="310.541"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_580_4633"
            x1="218.348"
            y1="10.1379"
            x2="151.115"
            y2="319.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_580_4633"
            x1="220.622"
            y1="0.0773128"
            x2="148.742"
            y2="328.902"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197816" stopColor="#0066CC" />
            <stop offset="0.513542" stopColor="#EB3300" />
            <stop offset="1" stopColor="#EB8D00" />
          </linearGradient>
        </defs>
      </svg> */}
    </div>
  );
};

export default WhyUsMobileVector;
