import React, { useRef, useLayoutEffect } from "react";
import CTAButton from "../../../CTAButton/CTAButton";
import * as solutionStyles from "./solution.module.scss";
import solutionImg from "../../../../assets/images/solution-img.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";
gsap.registerPlugin(ScrollTrigger);

const Solution = () => {
  const descString =
    "We have got plenty for you too. Let’s help you choose the best. Reliable software solutions only for firms who want the best way forward for their business. Pure field experience and domain knowledge. No fluff.";

  const titleString = "Every problem has a solution.";

  const solutionSectionRef = useRef();

  useLayoutEffect(() => {
    const fadeInUpSolutionTextAnimation = gsap.fromTo(
      `.solution-tweenWrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        stagger: {
          from: "start",
          amount: 0.3,
        },
        duration: 1,
        scrollTrigger: {
          id: "solutions text",
          trigger: solutionSectionRef.current,
          start: "top center-=200",
          end: "top top",
          markers: false,
        },
      }
    );

    const bgColorToggleTween = gsap.to("section", {
      scrollTrigger: {
        trigger: solutionSectionRef.current,
        start: "top top+=30%",
        onEnter: () => {
          const bodyElem = document.querySelectorAll(".es-section__dark");
          bodyElem.forEach((section, index) => {
            section.style.background = "var(--charcoal)";
            section.classList.remove("es-section__dark-text");
            section.classList.add("es-section__white-text");
          });
          const bodyWhiteElem = document.querySelectorAll(".es-section__blue");
          bodyWhiteElem.forEach((section, index) => {
            section.style.background = "var(--blue)";
            section.classList.add("es-section__white-text");
            section.classList.remove("es-section__dark-text");
          });
        },
        onLeaveBack: () => {
          const bodyElem = document.querySelectorAll(".es-section__dark");
          bodyElem.forEach((section, index) => {
            section.style.background = "var(--charcoal)";
            section.classList.remove("es-section__white-text");
          });
        },
      },
    });

    return () => {
      bgColorToggleTween.kill();
      fadeInUpSolutionTextAnimation.kill();
    };
  }, []);

  return (
    <section
      ref={solutionSectionRef}
      className={`es-section es-section__dark es-pb-0 es-centered-full-viewport ${solutionStyles.es_section}`}
    >
      <div className={solutionStyles.solution_content}>
        <h2
          className={`es-mb-utility-56-28 solution-tweenWrapper ${solutionStyles.solution_title}`}
        >
          {titleString}
        </h2>
        <div className={solutionStyles.solutionRow}>
          <div className="es-col solution-tweenWrapper">
            <div className={solutionStyles.solution_img_wrapper}>
              <img
                width="100%"
                height="100%"
                loading="lazy"
                src={solutionImg}
                alt=""
              />
            </div>
          </div>
          <div
            className={`es-col solution-tweenWrapper ${solutionStyles.text_cta_col}`}
          >
            <div className={solutionStyles.text_cta_wrapper}>
              <p>{descString}</p>
              <CTAButton
                type="button"
                size="btn-lg"
                text="Let’s go"
                rounded={true}
                outlined={true}
                btnBG="es-btn-white"
                spacingClasses=""
                additionalClass="noHover"
                hasIcon={false}
                icon=""
                reverse={false}
                isLink={true}
                isShadow={false}
                href="/contact-us"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
