// extracted by mini-css-extract-plugin
export var whyUsCoverSection = "whyUsCover-module--whyUsCoverSection--gs7cd";
export var whyUsCoverContent = "whyUsCover-module--whyUsCoverContent--EqNyU";
export var whyUsCoverMobileTitle = "whyUsCover-module--whyUsCoverMobileTitle--pbL-X";
export var dark_section = "whyUsCover-module--dark_section--xpY3o";
export var subtitle = "whyUsCover-module--subtitle--km5JG";
export var vectorsWrapper = "whyUsCover-module--vectorsWrapper--CIaJo";
export var whyUsCoverImg = "whyUsCover-module--whyUsCoverImg--QLNha";
export var whyUsCoverText = "whyUsCover-module--whyUsCoverText--4Xsg9";
export var whyUsCoverTextContent = "whyUsCover-module--whyUsCoverTextContent--EwwCd";
export var whyUsRow = "whyUsCover-module--whyUsRow--fYXlC";
export var whyUsCol = "whyUsCover-module--whyUsCol--ZdE-Z";