import React from "react";

const StarVector = () => {
  return (
    <>
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6824 8.76691C8.45454 6.93561 6.64935 4.75474 5.29283 2.21387C5.02152 1.70778 4.39024 1.51474 3.87894 1.78604C3.37285 2.05735 3.17977 2.68866 3.45107 3.19997C4.94325 5.98084 6.91546 8.37041 9.3572 10.3791C9.80067 10.7443 10.4633 10.6817 10.8285 10.2382C11.1937 9.78953 11.1258 9.13213 10.6824 8.76691Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49429 1.63476C9.60907 3.74259 9.71344 5.85041 9.84388 7.95824C9.8804 8.53215 10.3761 8.97041 10.95 8.93389C11.5239 8.89736 11.9621 8.40172 11.9256 7.8278C11.7952 5.72519 11.6908 3.6226 11.576 1.51477C11.5447 0.942423 11.0491 0.501551 10.4752 0.533899C9.90125 0.566247 9.45777 1.05928 9.49429 1.63476Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.24 2.06262C15.9044 4.07653 14.3914 5.9339 12.8679 7.81216C12.5027 8.26086 12.5705 8.91828 13.0192 9.27828C13.4679 9.6435 14.1253 9.57565 14.4853 9.12696C16.0505 7.20174 17.6053 5.28695 18.9827 3.21564C19.3009 2.73564 19.1652 2.08871 18.6852 1.77045C18.2105 1.45219 17.5583 1.58262 17.24 2.06262Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4139 9.54955C18.793 9.71129 14.9478 9.71651 11.327 9.48695C10.753 9.45564 10.2574 9.88869 10.2209 10.4678C10.1844 11.0417 10.6226 11.5374 11.1965 11.5739C14.8904 11.8035 18.8139 11.7982 22.5079 11.6365C23.0818 11.6104 23.5304 11.12 23.5043 10.5461C23.4783 9.97218 22.9878 9.52347 22.4139 9.54955Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.35436 10.2121C11.5874 12.946 13.8987 15.6278 15.97 18.4869C16.3092 18.9512 16.9614 19.0556 17.4309 18.7164C17.8953 18.3773 17.9996 17.7251 17.6605 17.2608C15.5631 14.3703 13.2257 11.6573 10.9666 8.89208C10.6014 8.4486 9.94394 8.38079 9.50046 8.74601C9.05177 9.11123 8.98914 9.76862 9.35436 10.2121Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0306 11.0054C10.615 13.8541 10.7767 16.5776 10.8185 19.4628C10.8237 20.0367 11.2985 20.4958 11.8724 20.4906C12.4515 20.4802 12.9106 20.0106 12.9002 19.4314C12.8585 16.4106 12.6863 13.5671 12.0759 10.5828C11.9559 10.0193 11.4081 9.65405 10.8394 9.77405C10.2759 9.88883 9.91064 10.4419 10.0306 11.0054Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1248 10.7759C8.43441 14.1568 7.5631 17.9133 5.75267 21.2212C5.47615 21.722 5.66393 22.3585 6.17002 22.635C6.67611 22.9116 7.30746 22.7237 7.58398 22.2228C9.40485 18.8942 10.2866 15.1168 11.9926 11.7098C12.2483 11.1933 12.0396 10.5672 11.5283 10.3063C11.0118 10.0507 10.3857 10.2594 10.1248 10.7759Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0552 10.0087C7.68995 10.6035 4.32995 10.8748 0.943864 11.2765C0.369951 11.3443 -0.0370052 11.8609 0.0308208 12.4348C0.0986469 13.0087 0.615217 13.4156 1.18913 13.3478C4.61174 12.9461 8.01347 12.6643 11.4204 12.0643C11.9839 11.96 12.3648 11.4174 12.2656 10.8539C12.1613 10.2852 11.6239 9.90433 11.0552 10.0087Z"
          fill="#EB3300"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3969 8.42288C12.703 8.39158 12.0456 8.34461 11.3673 8.24547C10.7934 8.162 10.2665 8.55852 10.183 9.12722C10.0995 9.69591 10.496 10.2281 11.0647 10.3116C11.816 10.4211 12.5412 10.4733 13.303 10.5046C13.8769 10.5307 14.3673 10.0872 14.3882 9.50807C14.4142 8.93416 13.9708 8.44897 13.3969 8.42288Z"
          fill="#EB3300"
        />
      </svg>
    </>
  );
};

export default StarVector;
